import { Cancel as CancelIcon, Done as DoneIcon } from '@mui/icons-material'
import CloseIcon from '@mui/icons-material/Close'
import {
  Autocomplete,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  IconButton,
  Stack,
} from '@mui/material'
import AsyncActionButton from '@src/components/AsyncActionButton'
import { SimpleDatePicker } from '@src/components/DatePicker'
import InputTextField from '@src/components/InputTextField'
import SelectComponent from '@src/components/SelectComponent'
import { CreditDashboardFilters } from '@src/data/api/credit-api/credit-api'
import { useMerchantByParentId } from '@src/data/api/merchants-api/merchants-api'
import { Merchant } from '@src/types/Merchant'
import { memo, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useUser } from '@src/contexts/AppContext'
import { EMerchantType, EOriginSystemId } from '@src/types/Constants'
import { useCreditApplicationStatuses, useCurrentCustomerSteps } from './creditEntriesFiltersDialog-hooks'

type Props = {
  filterValues: CreditDashboardFilters
  open: boolean
  merchant: Merchant | null
  onApplyFilters: (filters: CreditDashboardFilters) => void
  onCancel: () => void
  handleClearFilter: () => void
}

const CreditEntriesFiltersDialog = ({
  filterValues,
  open,
  merchant,
  onApplyFilters,
  onCancel,
  handleClearFilter,
}: Props) => {
  const { t } = useTranslation()

  const { register, handleSubmit, watch, setValue, reset, control } = useForm<CreditDashboardFilters>({
    defaultValues: filterValues,
  })

  const [merchantsList, isGettingMerchants] = useMerchantByParentId(merchant?.id ?? '')
  const user = useUser()
  const creditApplicationStatuses = useCreditApplicationStatuses()
  const currentCustomerSteps = useCurrentCustomerSteps()

  const selectedMerchantId = watch('merchantId')

  useEffect(() => {
    if (!open) {
      reset(filterValues)
    }
  }, [open, filterValues, reset])

  return (
    <Dialog
      open={open}
      onClose={onCancel}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth="md"
      keepMounted
      transitionDuration={0}
      disablePortal
    >
      <DialogTitle style={{ borderBottom: '1px solid' }} id="alert-dialog-title" sx={{ textAlign: 'center' }}>
        {t('common.filters')}
        <IconButton
          aria-label="close"
          onClick={onCancel}
          sx={{
            position: 'absolute',
            right: 15,
            top: 15,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <form onSubmit={handleSubmit(onApplyFilters)}>
        <DialogContent>
          {isGettingMerchants ? (
            <div style={{ textAlign: 'center' }}>
              <CircularProgress />
            </div>
          ) : (
            <Grid item container direction="column" spacing={2} sx={{ mt: 5, width: 600 }}>
              <Grid item>
                <Autocomplete
                  id="merchantList"
                  options={merchantsList}
                  value={merchantsList.find((m) => m.id === selectedMerchantId) ?? null}
                  onChange={(_event, selectedMerchant: Merchant | null) => {
                    if (selectedMerchant === null) setValue('merchantId', '')
                    else setValue('merchantId', selectedMerchant?.id)
                  }}
                  disabled={merchant?.merchantTypeId !== EMerchantType.Banner}
                  autoHighlight
                  getOptionLabel={(option) => option.name}
                  noOptionsText={t('common.searchYieldsNoResults')}
                  renderInput={(merchantList) => (
                    <InputTextField
                      placeholder={t('common.all')}
                      {...merchantList}
                      label={t('common.merchant')}
                      inputProps={{
                        ...merchantList.inputProps,
                      }}
                    />
                  )}
                />
              </Grid>
              <Grid item>
                <SelectComponent
                  valueList={creditApplicationStatuses}
                  label={t('common.status') as string}
                  {...register('creditApplicationStatus')}
                />
              </Grid>
              <Grid item>
                <SelectComponent
                  valueList={currentCustomerSteps}
                  label={t('common.state') as string}
                  {...register('currentCustomerStep')}
                />
              </Grid>

              <Grid item justifyContent="center">
                <Stack justifyContent="space-between" direction="row">
                  <SimpleDatePicker
                    label={t('common.startDate')}
                    sx={{ minWidth: '49%' }}
                    name="startDate"
                    control={control}
                  />

                  <SimpleDatePicker
                    label={t('common.endDate')}
                    sx={{ minWidth: '49%' }}
                    name="endDate"
                    control={control}
                  />
                </Stack>
              </Grid>

              <Grid item>
                <FormControlLabel
                  label={t('common.requiresMerchantAttention')}
                  control={
                    <Checkbox
                      checked={watch('requiresMerchantAttention')}
                      {...register('requiresMerchantAttention')}
                      inputProps={{ 'aria-label': 'controlled' }}
                    />
                  }
                />
              </Grid>
              <Grid item>
                <FormControlLabel
                  label={t('common.editableApplications')}
                  control={
                    <Checkbox
                      checked={watch('originSystemId') !== ''}
                      onChange={(e) => {
                        const isChecked = e.target.checked
                        if (isChecked) {
                          setValue('originSystemId', EOriginSystemId.MerchantDashboard)
                        } else {
                          setValue('originSystemId', '')
                        }
                      }}
                      inputProps={{ 'aria-label': 'controlled' }}
                    />
                  }
                />
              </Grid>
              <Grid item>
                <FormControlLabel
                  label={t('common.currentUserApplications')}
                  control={
                    <Checkbox
                      checked={watch('createdByUserId') !== ''}
                      onChange={(e) => {
                        const isChecked = e.target.checked
                        if (isChecked && user) {
                          setValue('createdByUserId', user.id)
                        } else {
                          setValue('createdByUserId', '')
                        }
                      }}
                      inputProps={{ 'aria-label': 'controlled' }}
                    />
                  }
                />
              </Grid>
            </Grid>
          )}
        </DialogContent>
        <DialogActions>
          <AsyncActionButton startIcon={<CancelIcon />} onClick={handleClearFilter}>
            {t('common.reset')}
          </AsyncActionButton>
          <AsyncActionButton variant="contained" type="submit" startIcon={<DoneIcon />}>
            {t('common.apply')}
          </AsyncActionButton>
        </DialogActions>
      </form>
    </Dialog>
  )
}

export default memo(CreditEntriesFiltersDialog)
