import { useEffect, useState } from 'react'
import { Grid, Paper, Box, Divider, Typography } from '@mui/material'
import {
  FieldError,
  FieldErrorsImpl,
  Merge,
  UseFormRegister,
  UseFormSetValue,
  UseFormTrigger,
  UseFormWatch,
} from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import SelectComponent from '@src/components/SelectComponent'
import { SelectValueListItem } from '@src/types/SelectValueListItem'
import InputTextField from '@src/components/InputTextField'
import { ApplicantExpenses } from '@src/types/CreditApplicationSchema'
import { EHomeStatus } from '@src/types/Constants'

type Props = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  register: UseFormRegister<any>
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  watch: UseFormWatch<any>
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setValue: UseFormSetValue<any>
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  trigger: UseFormTrigger<any>
  errors?: Merge<FieldError, FieldErrorsImpl<ApplicantExpenses>>
  name: string
  showDetailedForm: boolean
}

const ApplicantExpensesSection = ({ register, watch, setValue, trigger, errors, name, showDetailedForm }: Props) => {
  const { t } = useTranslation()
  const monthlyOtherExpensePayment = String(watch(`${name}.monthlyOtherExpensePayment`))
  const houseFeeType = String(watch(`${name}.homeFeeTypeId`)) as EHomeStatus
  const [isHouseMarketLocked, setIsHouseMarketLocked] = useState<boolean>(false)
  const [isOwner, setIsOwner] = useState<boolean>(false)

  useEffect(() => {
    if (houseFeeType === EHomeStatus.Owner) {
      setIsHouseMarketLocked(false)
      setIsOwner(true)
    } else if (
      houseFeeType === EHomeStatus.WithParents ||
      houseFeeType === EHomeStatus.Renter ||
      houseFeeType === EHomeStatus.ReserveHousing
    ) {
      setValue(`${name}.houseMarketValue`, undefined, { shouldValidate: true })
      setIsHouseMarketLocked(true)
      setIsOwner(false)
    } else if (houseFeeType) {
      setIsHouseMarketLocked(false)
      setIsOwner(false)
    }
  }, [houseFeeType, name, setValue, trigger])

  const isInvalidOtherExpensePayment =
    !monthlyOtherExpensePayment ||
    monthlyOtherExpensePayment.toString().trim() === '' ||
    monthlyOtherExpensePayment.toString().trim() === '0'
  useEffect(() => {
    if (isInvalidOtherExpensePayment) {
      setValue(`${name}.otherExpensePaymentDescription`, '')
    }
  }, [isInvalidOtherExpensePayment, monthlyOtherExpensePayment, name, setValue])

  return (
    <Paper elevation={3} sx={{ borderRadius: 2, p: 2, mb: 3 }}>
      <Divider>
        <Typography variant="h6" gutterBottom>
          {t('editCreditApplication.expenses.recurringMonthlyHomeRelatedDisbursements')}
        </Typography>
      </Divider>
      <Box component="fieldset" sx={{ my: 1, mx: 2 }}>
        <legend>{t('editCreditApplication.expenses.homeFeesLebel')}</legend>
        <Grid
          container
          item
          xs={12}
          spacing={2}
          alignContent="space-between"
          sx={{ p: 1 }}
          style={{ flexWrap: 'inherit' }}
        >
          <Grid item xs={12} alignContent="space-between">
            <SelectComponent
              valueList={Object.values(EHomeStatus).map((item) => {
                const data: SelectValueListItem = { label: `homeStatus.${item}`, value: item }
                return data
              })}
              label={t('editCreditApplication.expenses.homeFeeTypeId') as string}
              {...register(`${name}.homeFeeTypeId`)}
              error={errors?.homeFeeTypeId}
            />
          </Grid>
          {!isOwner && (
            <Grid item xs={12}>
              <InputTextField
                id="monthlyHomePayment"
                error={errors?.housingMonthly}
                label={t('editCreditApplication.expenses.monthlyHomePayment')}
                {...register(`${name}.housingMonthly`)}
              />
            </Grid>
          )}
          <Grid item xs={12}>
            <InputTextField
              id="houseMarketValue"
              disabled={isHouseMarketLocked}
              error={errors?.houseMarketValue}
              label={t('editCreditApplication.expenses.houseMarketValue')}
              {...register(`${name}.houseMarketValue`)}
            />
          </Grid>
        </Grid>
      </Box>
      {showDetailedForm && (
        <>
          <Box component="fieldset" sx={{ m: 2 }}>
            <legend>{t('editCreditApplication.expenses.others')}</legend>
            <Grid container item xs={12} spacing={2} alignContent="space-between" sx={{ p: 1 }}>
              <Grid item xs={12} md={3}>
                <InputTextField
                  id="monthlyOwnerInsuranceFee"
                  error={errors?.monthlyOwnerInsuranceFee}
                  label={t('editCreditApplication.expenses.monthlyOwnerInsuranceFee')}
                  {...register(`${name}.monthlyOwnerInsuranceFee`)}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <InputTextField
                  id="monthlyMortgageInsuranceFee"
                  error={errors?.monthlyMortgageInsuranceFee}
                  label={t('editCreditApplication.expenses.monthlyMortgageInsuranceFee')}
                  {...register(`${name}.monthlyMortgageInsuranceFee`)}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <InputTextField
                  id="monthlyMunicipalAndSchoolTaxPayment"
                  error={errors?.monthlyMunicipalAndSchoolTaxPayment}
                  label={t('editCreditApplication.expenses.monthlyMunicipalAndSchoolTaxPayment')}
                  {...register(`${name}.monthlyMunicipalAndSchoolTaxPayment`)}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <InputTextField
                  id="monthlyCondominiumFee"
                  error={errors?.monthlyCondominiumFee}
                  label={t('editCreditApplication.expenses.monthlyCondominiumFee')}
                  {...register(`${name}.monthlyCondominiumFee`)}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <InputTextField
                  id="monthlyPublicServiceFee"
                  error={errors?.monthlyPublicServiceFee}
                  label={t('editCreditApplication.expenses.monthlyPublicServiceFee')}
                  {...register(`${name}.monthlyPublicServiceFee`)}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <InputTextField
                  id="monthlyRrspRefund"
                  error={errors?.monthlyRrspRefund}
                  label={t('editCreditApplication.expenses.monthlyRrspRefund')}
                  {...register(`${name}.monthlyRrspRefund`)}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <InputTextField
                  id="monthlyOtherHomeFee"
                  error={errors?.monthlyOtherHomeFee}
                  label={t('editCreditApplication.expenses.monthlyOtherHomeFee')}
                  {...register(`${name}.monthlyOtherHomeFee`)}
                />
              </Grid>
            </Grid>
          </Box>
          <Divider>
            <Typography variant="h5" component="span" gutterBottom>
              {t('editCreditApplication.expenses.monthlyDisbursementsWithCreditAgreement')}
            </Typography>
          </Divider>
          <Box component="fieldset" sx={{ m: 2 }}>
            <legend>{t('editCreditApplication.expenses.loanAndCreditLabel')}</legend>
            <Grid container item xs={12} spacing={2} alignContent="space-between" sx={{ p: 1 }}>
              <Grid item xs={12} md={3}>
                <InputTextField
                  id="monthlyInstallmentLoanPayment"
                  error={errors?.monthlyInstallmentLoanPayment}
                  label={t('editCreditApplication.expenses.monthlyInstallmentLoanPayment')}
                  {...register(`${name}.monthlyInstallmentLoanPayment`)}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <InputTextField
                  id="monthlyStudentLoanPayment"
                  error={errors?.monthlyStudentLoanPayment}
                  label={t('editCreditApplication.expenses.monthlyStudentLoanPayment')}
                  {...register(`${name}.monthlyStudentLoanPayment`)}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <InputTextField
                  id="monthlyLineOfCreditPayment"
                  error={errors?.monthlyLineOfCreditPayment}
                  label={t('editCreditApplication.expenses.monthlyLineOfCreditPayment')}
                  {...register(`${name}.monthlyLineOfCreditPayment`)}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <InputTextField
                  id="monthlyCreditCardsPayment"
                  error={errors?.monthlyCreditCardsPayment}
                  label={t('editCreditApplication.expenses.monthlyCreditCardsPayment')}
                  {...register(`${name}.monthlyCreditCardsPayment`)}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <InputTextField
                  id="monthlyPaydayLoanPayment"
                  error={errors?.monthlyPaydayLoanPayment}
                  label={t('editCreditApplication.expenses.monthlyPaydayLoanPayment')}
                  {...register(`${name}.monthlyPaydayLoanPayment`)}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <InputTextField
                  id="monthlyPersonalLoanPayment"
                  error={errors?.monthlyPersonalLoanPayment}
                  label={t('editCreditApplication.expenses.monthlyPersonalLoanPayment')}
                  {...register(`${name}.monthlyPersonalLoanPayment`)}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <InputTextField
                  id="monthlyOtherLoanPayment"
                  error={errors?.monthlyOtherLoanPayment}
                  label={t('editCreditApplication.expenses.monthlyOtherLoanPayment')}
                  {...register(`${name}.monthlyOtherLoanPayment`)}
                />
              </Grid>
            </Grid>
          </Box>
          <Box component="fieldset" sx={{ m: 2 }}>
            <legend>{t('editCreditApplication.expenses.others')}</legend>
            <Grid container item xs={12} spacing={2} alignContent="space-between" sx={{ p: 1 }}>
              <Grid item xs={12} md={4}>
                <InputTextField
                  id="monthlyOtherExpensePayment"
                  error={errors?.monthlyOtherExpensePayment}
                  label={t('editCreditApplication.expenses.monthlyOtherExpensePayment')}
                  {...register(`${name}.monthlyOtherExpensePayment`)}
                />
              </Grid>
              <Grid item xs={12} md={8}>
                <InputTextField
                  id="otherExpensePaymentDescription"
                  disabled={isInvalidOtherExpensePayment}
                  error={errors?.otherExpensePaymentDescription}
                  label={t('editCreditApplication.expenses.otherExpensePaymentDescription')}
                  {...register(`${name}.otherExpensePaymentDescription`)}
                  inputProps={{ maxLength: 50 }}
                />
              </Grid>
            </Grid>
          </Box>
        </>
      )}
    </Paper>
  )
}

ApplicantExpensesSection.defaultProps = {
  errors: undefined,
}
export default ApplicantExpensesSection
