import { EBeneficiaryType, EContractStatus, EDecision, EServiceCategory } from '@src/types/Constants'
import { CreditApplication } from '@src/types/CreditApplicationSchema'
import { SelectValueListItem } from '@src/types/SelectValueListItem'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

export const isServerAnalysingCredit = (creditApp: CreditApplication | null): boolean => {
  return (
    creditApp !== null &&
    ((creditApp.consentHardHit && !creditApp.normsCreditDecision) || creditApp.prequalificationDecision == null)
  )
}

export const canPollCreditApp = (creditApp: CreditApplication | null): boolean => {
  if (creditApp) {
    let maxTime = new Date()
    const thirtySeconds = 30 * 1000
    maxTime = new Date(maxTime.getTime() - thirtySeconds)
    const creditAppTime = new Date(creditApp.updatedOn)
    return creditAppTime >= maxTime
  }
  return false
}

export const isServerCreatingContract = (creditApp: CreditApplication | null): boolean => {
  return (
    creditApp !== null &&
    creditApp.worksheet?.finalDecision.decision === EDecision.Accept &&
    creditApp.contract.status === EContractStatus.notReady
  )
}

export const useBeneficiaryTypes = (loanPurposeId: EServiceCategory): SelectValueListItem[] => {
  const { t } = useTranslation()
  const beneficiaryTypesList: SelectValueListItem[] = useMemo(() => {
    const list: SelectValueListItem[] = []
    if (
      loanPurposeId &&
      [
        EServiceCategory.Aesthetics,
        EServiceCategory.Medical,
        EServiceCategory.Dentistry,
        EServiceCategory.GoodsAndServices,
      ].includes(loanPurposeId, 0)
    ) {
      list.push(
        {
          value: EBeneficiaryType.Applicant,
          label: t('beneficiaryType.applicant'),
        },
        {
          value: EBeneficiaryType.Other,
          label: t('beneficiaryType.other'),
        },
      )
    } else {
      list.push({
        value: EBeneficiaryType.Other,
        label: t('beneficiaryType.other'),
      })
    }
    return list
  }, [loanPurposeId, t])

  return beneficiaryTypesList
}
