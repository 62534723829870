import { IApplicationInsights } from '@microsoft/applicationinsights-web'

let appInsights: IApplicationInsights | null = null

export function setupTracking(ai: IApplicationInsights): void {
  appInsights = ai
}

export function reportErrorToConsole(error: unknown) {
  console.error(error)
}
export function reportErrorToServer(error: Error | string) {
  reportErrorToConsole(error)
  appInsights?.trackException({ error: error instanceof Error ? error : new Error(error), severityLevel: 3 })
}
