import { EApplicantType, EDocumentStatus, EStatusBackgroundColor, EStatusTextColor } from '@src/types/Constants'
import { RequiredDocument } from '@src/types/RequiredDocument'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

export function useDocumentStatus(documentRequired: RequiredDocument) {
  const { t } = useTranslation()

  const { statusMessage, textColor, backgroundColor } = useMemo(() => {
    let cStatusMessage: string
    let cTextColor: EStatusTextColor
    let cBackgroundColor: EStatusBackgroundColor

    switch (documentRequired.status) {
      case EDocumentStatus.Approved:
        cStatusMessage = t('documentStatuses.approved')
        cTextColor = EStatusTextColor.success
        cBackgroundColor = EStatusBackgroundColor.success
        break
      case EDocumentStatus.AwaitingApproval:
        cStatusMessage = t('documentStatuses.awaitingApproval')
        cTextColor = EStatusTextColor.info
        cBackgroundColor = EStatusBackgroundColor.info
        break
      case EDocumentStatus.Refused:
        cStatusMessage = t('documentStatuses.refused')
        cTextColor = EStatusTextColor.error
        cBackgroundColor = EStatusBackgroundColor.error
        break
      case EDocumentStatus.Incomplete:
        cStatusMessage = t('documentStatuses.incomplete')
        cTextColor = EStatusTextColor.warning
        cBackgroundColor = EStatusBackgroundColor.warning
        break
      default:
        cStatusMessage = t('documentStatuses.awaitingUpload')
        cTextColor = EStatusTextColor.warning
        cBackgroundColor = EStatusBackgroundColor.warning
    }
    return { statusMessage: cStatusMessage, textColor: cTextColor, backgroundColor: cBackgroundColor }
  }, [documentRequired.status, t])

  return { statusMessage, textColor, backgroundColor }
}

export function useFullNameText(documentRequired: RequiredDocument) {
  const { t } = useTranslation()

  const fullNameText = useMemo(() => {
    let applicantSuffix = ''
    if (documentRequired.applicantType === EApplicantType.Applicant) {
      applicantSuffix = ` - ${t('common.applicant')}`
    } else if (documentRequired.applicantType === EApplicantType.Coapplicant) {
      applicantSuffix = ` - ${t('common.coApplicant')}`
    }

    return t('common.currentLanguage') === 'Fr'
      ? `${documentRequired.nameFr}${applicantSuffix}`
      : `${documentRequired.nameEn}${applicantSuffix}`
  }, [documentRequired, t])

  return fullNameText
}
