import { Paper, Stack, Typography } from '@mui/material'
import { ReactNode } from 'react'
import { IconType } from '@src/types/Constants'
import IconSection from './iconSection'

type Props = {
  title: string
  subtitle?: React.ReactNode
  children: ReactNode
  iconMessage?: string | null
  iconType?: IconType | null
  onFooterClicked?: () => void
}

const StepStatusSection = ({ title, subtitle, children, iconType, iconMessage, onFooterClicked }: Props) => {
  return (
    <Paper
      elevation={2}
      sx={{
        minWidth: '290px',
        maxWidth: '290px',
        minHeight: '40vh',
        borderRadius: 2,
        p: 1,
        borderTop: `solid .6rem #14233a`,
        m: 1,
      }}
      component={Stack}
      spacing={2}
      justifyContent="space-between"
    >
      <div>
        <Typography variant="h5">{title}</Typography>
        {subtitle && (
          <p style={{ margin: '0', fontSize: 'smaller', fontWeight: 'lighter', color: 'darkGray' }}>{subtitle}</p>
        )}
      </div>
      {children}
      <Stack>
        {iconMessage && <IconSection iconMessage={iconMessage} iconType={iconType} onClick={onFooterClicked} />}
      </Stack>
    </Paper>
  )
}

export default StepStatusSection
