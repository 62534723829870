import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import useVersionCheck from '@src/data/api/version-check'
import AlertBanner from './AlertBanner'

const isRunningLocal = process.env.NODE_ENV === 'development'

const AlertBannerOnDeprecatedStatus = () => {
  const { t } = useTranslation()
  const [version, isFetchingVersion] = useVersionCheck()

  const shouldShowDeprecated = useMemo<boolean>(() => {
    return !isRunningLocal && !isFetchingVersion && Boolean(version) && version !== process.env.REACT_APP_VERSION
  }, [isFetchingVersion, version])

  return <AlertBanner message={t('common.errors.deprecated')} show={shouldShowDeprecated} />
}

export default AlertBannerOnDeprecatedStatus
