import { Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

const GetCreditContentConsentText = () => {
  const { t } = useTranslation()
  return (
    <Typography component="div">
      <p>{t('submission.iHaveInformedTheCustomer')}</p>
      <ol>
        <li>{t('submission.contentIsCorrect')}</li>
        <li>{t('submission.creditCheckSent')}</li>
        <li>{t('submission.createTemporaryNote')}</li>
        <li>{t('submission.creditReportSignedKept')}</li>
      </ol>
      <p>
        {t('submission.iHaveShownPrivacyPolicyToCustomer')}{' '}
        <Link
          to="https://ifinancecanada.com/privacy-policy/"
          style={{
            color: '#90caf9',
          }}
          target="_blank"
        >
          {t('incomeVerification.startVerification.termsAndConditions')}
        </Link>
      </p>
    </Typography>
  )
}

export default GetCreditContentConsentText
