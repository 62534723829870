import { Address } from '@src/types/AddressSchema'
import { EHomeStatus } from '@src/types/Constants'
import {
  ApplicantDto,
  ApplicantExpenses,
  ApplicantJob,
  CreditApplication,
  DraftCreditApplicationDto,
} from '@src/types/CreditApplicationSchema'
import { omit, toInteger } from 'lodash-es'

export function transformApplicantExpensesToApi(expenses: ApplicantExpenses): ApplicantExpenses {
  if (
    (expenses.homeFeeTypeId === EHomeStatus.OwnWithMortgage ||
      expenses.homeFeeTypeId === EHomeStatus.OwnMobileHome ||
      expenses.homeFeeTypeId === EHomeStatus.Owner) &&
    !expenses.houseMarketValue
  ) {
    expenses.houseMarketValue = null
  }
  if (expenses.homeFeeTypeId === EHomeStatus.OwnWithMortgage) {
    expenses.mortgageMonthly = expenses.housingMonthly
  } else if (expenses.homeFeeTypeId === EHomeStatus.Renter) {
    expenses.rentMonthly = expenses.housingMonthly
  }
  return expenses
}

function transformApplicantJobToApi(job: ApplicantJob): ApplicantJob {
  const ret = omit(job, 'years') as ApplicantJob
  ret.months = (toInteger(job.years) ?? 0) * 12 + (toInteger(job.months) ?? 0)
  return ret
}

function transformApplicantAddressToApi(address: Address): Address {
  const ret = omit(address, 'years') as Address
  ret.months = toInteger((address.years ?? 0) * 12) + toInteger(address.months ?? 0)
  return ret
}

function transformExpenseFromApi(expenses: ApplicantExpenses): ApplicantExpenses {
  if (expenses.rentMonthly || expenses.mortgageMonthly)
    expenses.housingMonthly = expenses.rentMonthly! + expenses.mortgageMonthly!
  return expenses
}

function transformApplicantJobFromApi(job: ApplicantJob): ApplicantJob {
  const ret = { ...job } as ApplicantJob
  if (job.months !== null) {
    ret.months = job.months % 12
    ret.years = (job.months - ret.months) / 12
    if (ret.months === 0) ret.months = null
  } else {
    ret.months = null
    ret.years = null
  }
  return ret
}

function transformAddressFromApi(currentAddress: Address): Address {
  const ret = { ...currentAddress } as Address
  ret.months = currentAddress.months! % 12
  ret.years = (currentAddress.months! - ret.months) / 12
  if (ret.months === 0) ret.months = null
  if (ret.years === 0) ret.years = null
  return ret
}

export function transformApplicantToApi(applicant: ApplicantDto) {
  return {
    ...applicant,
    expenses: transformApplicantExpensesToApi(applicant.expenses as ApplicantExpenses),
    currentJobs: applicant.currentJobs.map(transformApplicantJobToApi),
    currentAddress: transformApplicantAddressToApi(applicant.currentAddress),
  } as ApplicantDto
}

function transformApplicantFromApi(applicant: ApplicantDto) {
  return {
    ...applicant,
    expenses: transformExpenseFromApi(applicant.expenses as ApplicantExpenses),
    currentJobs: applicant.currentJobs.map(transformApplicantJobFromApi),
    currentAddress: transformAddressFromApi(applicant.currentAddress),
  } as ApplicantDto
}

export function transformCreditApplicationToApi(creditApp: Partial<DraftCreditApplicationDto>) {
  return {
    ...creditApp,
    applicant: transformApplicantToApi(creditApp.applicant!),
    coApplicant: creditApp?.coApplicant ? transformApplicantToApi(creditApp.coApplicant) : null,
  }
}

export function transformCreditApplicationFromApi(creditApp: CreditApplication) {
  return {
    ...creditApp,
    applicant: transformApplicantFromApi(creditApp.applicant),
    coApplicant: creditApp?.coApplicant ? transformApplicantFromApi(creditApp.coApplicant) : null,
  }
}
