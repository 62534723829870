import { MutationFunction, QueryFunctionContext, useMutation, useQuery } from '@tanstack/react-query'
import { useMemo } from 'react'
import { ValueListDto, ValueListItem, ValueListItemDto } from '@src/types/ValueListDto'
import { getApiClient } from '../api-client'

const SCOPE = 'cancel-reasons'

const keysFactory = {
  cancelReasons: () => [{ scope: SCOPE }] as const,
}

export type CustomerFeedback = {
  withdrawalReasonId: string | null
  contactClient: boolean
  isSatisfied: boolean
  badReviewReasonId: string | null
}

export type CustomerFeedbackDto = CustomerFeedback & {
  creditApplicationId: string
}
const submitFeedback: MutationFunction<CustomerFeedbackDto, CustomerFeedbackDto> = async (dto: CustomerFeedbackDto) => {
  const apiClient = getApiClient()
  const response = await apiClient.post(`CustomerFeedback/${dto.creditApplicationId}/CustomerFeedback`, dto)
  const ret = response.data as CustomerFeedbackDto
  ret.creditApplicationId = dto.creditApplicationId
  return ret
}

export function useSubmitFeedback(): [
  MutationFunction<CustomerFeedbackDto, CustomerFeedbackDto>,
  boolean,
  boolean,
  () => void,
] {
  const { mutateAsync, isPending, isError, reset } = useMutation({
    mutationFn: submitFeedback,
    onSuccess: (data) => {
      return data
    },
  })

  return [mutateAsync, isPending, isError, reset]
}

const getCancelReasons = async ({
  // eslint-disable-next-line no-empty-pattern
  queryKey: [],
}: QueryFunctionContext<ReturnType<(typeof keysFactory)['cancelReasons']>>) => {
  const apiClient = getApiClient()
  const response = await apiClient.get<ValueListDto>(`/CustomerFeedback/WithdrawalReasons`)

  return response.data
}

function buildValueList(list: ValueListDto | undefined, lang: string) {
  if (!list) return []

  const inputList: ValueListItemDto[] = [...list.items]
  if (list.sortMethod === 'priority') inputList.sort((x) => x.priority ?? 0)

  const items: ValueListItem[] = inputList.map((x) => {
    return {
      id: x.value,
      text: lang === 'fr' ? x.textFr : x.textEn,
      contactClient: x.contactClient,
    }
  })
  if (list.sortMethod === 'alpha') items.sort((one, two) => (one.text > two.text ? -1 : 1))
  return items
}

export function useCancelReasons(lang: string): [ValueListItem[], boolean] {
  const { isFetching, data } = useQuery({
    queryKey: keysFactory.cancelReasons(),
    queryFn: getCancelReasons,
  })

  const list = useMemo(() => {
    return buildValueList(data, lang)
  }, [data, lang])

  return [list, isFetching]
}
