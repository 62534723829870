import { Box, Divider, Grid, Typography } from '@mui/material'
import { ApplicantJob, ApplicantOtherIncome, JobSchema } from '@src/types/CreditApplicationSchema'
import { nanoid } from 'nanoid'
import React from 'react'
import { Control, FieldError, FieldErrorsImpl, Merge, UseFormRegister, useFieldArray, useWatch } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import AsyncActionButton from '@src/components/AsyncActionButton'
import DisplayJobInfosComponent from './DisplayJobsInfosComponent'
import EditJobDialog from './EditJobDialog'
import OtherIncomeComponent from './OtherIncomeComponent'

type Props = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  register: UseFormRegister<any>
  errors: Merge<FieldError, (Merge<FieldError, FieldErrorsImpl<ApplicantOtherIncome>> | undefined)[]> | undefined
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  formControl: Control<any>
  name: 'coApplicant.' | 'applicant.' | ''
  disableEdit: boolean
}
const ApplicantIncomeSection = ({ register, errors, name, formControl, disableEdit }: Props) => {
  const [open, setOpen] = React.useState<boolean>(false)
  const [editingItemIndex, setEditingItemIndex] = React.useState<number | undefined>(undefined)
  const handleClose = (event?: object, reason?: string) => {
    if (reason !== 'backdropClick') {
      setEditingItemIndex(undefined)
      setOpen(false)
    }
  }

  const handleOpen = (itemIdex?: number) => {
    setEditingItemIndex(itemIdex)
    setOpen(true)
  }

  const { t } = useTranslation()

  const {
    fields: otherIncomesFields,
    append: otherIncomesAppend,
    remove: otherIncomesRemove,
  } = useFieldArray({
    name: `${name}otherIncomes`,
    control: formControl,
  })

  const {
    append: currentJobsAppend,
    remove: currentJobsRemove,
    update: currentJobsUpdate,
  } = useFieldArray({ name: `${name}currentJobs`, control: formControl })

  const currentJobsFields = useWatch({
    control: formControl,
    name: `${name}currentJobs`,
  }) as ApplicantJob[]

  const addJobHandler = (data: ApplicantJob) => {
    currentJobsAppend(data)
    setOpen(false)
  }

  const updateJobHandler = (data: ApplicantJob, itemIndex: number) => {
    currentJobsUpdate(itemIndex, data)
    handleClose()
  }

  // Others Icomes
  const addOtherIncomesHandler = () => {
    otherIncomesAppend({ id: nanoid() } as ApplicantOtherIncome)
  }

  return (
    <>
      <Divider>
        <Typography variant="h6" gutterBottom>
          {t('editCreditApplication.income.label')}
        </Typography>
      </Divider>

      <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2, m: 3 }}>
        <Divider textAlign="left" sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
          <Typography variant="h6" gutterBottom>
            {t('editCreditApplication.income.job.label')}
          </Typography>
        </Divider>
        <Box sx={{ flex: '1 1 auto' }} />
        <AsyncActionButton
          fullWidth={false}
          id="addJob"
          variant="outlined"
          onClick={() => handleOpen()}
          disabled={disableEdit}
        >
          {t('editCreditApplication.income.job.addJob')}
        </AsyncActionButton>
      </Box>

      <Grid container item xs={12}>
        {currentJobsFields &&
          currentJobsFields.map((item: ApplicantJob, index: number) => (
            <DisplayJobInfosComponent
              key={item.id}
              onDelete={() => currentJobsRemove(index)}
              onEdit={() => {
                handleOpen(index)
              }}
              data={item}
              disableEdit={disableEdit}
            />
          ))}

        <EditJobDialog
          defaultValue={
            editingItemIndex !== undefined
              ? currentJobsFields[editingItemIndex]
              : { ...(JobSchema.getDefault() as unknown as ApplicantJob), id: nanoid() }
          }
          onConfirm={(data: ApplicantJob) =>
            editingItemIndex !== undefined ? updateJobHandler(data, editingItemIndex) : addJobHandler(data)
          }
          onCancel={handleClose}
          open={open}
          title={
            editingItemIndex !== undefined
              ? t('editCreditApplication.income.job.editJob')
              : t('editCreditApplication.income.job.addJob')
          }
        />
      </Grid>

      <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2, m: 3 }}>
        <Divider textAlign="left" sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
          <Typography variant="h6" gutterBottom>
            {t('editCreditApplication.income.other.label')}
          </Typography>
        </Divider>
        <Box sx={{ flex: '1 1 auto' }} />
        <AsyncActionButton
          fullWidth={false}
          id="addOtherIncomes"
          variant="outlined"
          onClick={addOtherIncomesHandler}
          disabled={disableEdit}
        >
          {t('editCreditApplication.income.other.addOther')}
        </AsyncActionButton>
      </Box>

      <Grid container item xs={12} spacing={2}>
        {otherIncomesFields &&
          otherIncomesFields.map((item, index) => (
            <OtherIncomeComponent
              key={item.id}
              register={register}
              formControl={formControl}
              errors={errors?.[index]}
              name={`${name}otherIncomes.${index}`}
              onDeleteClicked={() => otherIncomesRemove(index)}
              disableEdit={disableEdit}
            />
          ))}
      </Grid>
    </>
  )
}
export default ApplicantIncomeSection
