import { Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import AsyncActionButton from '@src/components/AsyncActionButton'

import { EApplicantType } from '@src/types/Constants'
import { useTranslation } from 'react-i18next'
import FlinksOptionBox from './FlinksOptionBox'
import ManualOptionBox from './ManualOptionBox'

type Props = {
  open: boolean
  onClose: () => void
  applicantType: EApplicantType
  creditApplicationId: string
  canSkipFlinks: boolean
}

const BankAccountProcedureDialog = ({ open, onClose, applicantType, creditApplicationId, canSkipFlinks }: Props) => {
  const { t } = useTranslation()
  return (
    <Dialog open={open} onClose={onClose} maxWidth="lg" fullWidth keepMounted transitionDuration={0} disablePortal>
      <DialogTitle sx={{ textAlign: 'center' }}>
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 15,
            top: 15,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent sx={{ marginTop: '2rem' }}>
        <Grid container justifyContent="center">
          <FlinksOptionBox canSkipFlinks={canSkipFlinks} />
          {canSkipFlinks && <ManualOptionBox creditApplicationId={creditApplicationId} applicantType={applicantType} />}
        </Grid>
      </DialogContent>
      <DialogActions>
        <Grid container spacing={1} justifyContent="right" textAlign="center" marginRight={2}>
          <Grid item xs={12} md={4}>
            <AsyncActionButton variant="contained" onClick={onClose}>
              {t('incomeVerification.bankAccountProcedure.understand')}
            </AsyncActionButton>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  )
}

export default BankAccountProcedureDialog
