import { yupResolver } from '@hookform/resolvers/yup'
import { Grid, Paper, Typography } from '@mui/material'
import AsyncActionButton from '@src/components/AsyncActionButton'
import ApplicantParameters from '@src/containers/CreditApplicationPage/components/Applicant/ApplicantComponent'
import PageSpinner from '@src/components/PageSpinner'
import {
  PostCoapplicantParams,
  useCreditApplicationById,
  usePostCoapplicant,
} from '@src/data/api/credit-api/credit-api'
import { reportErrorToConsole } from '@src/services/error-logger'
import { ApplicantDto, FullApplicantSchema } from '@src/types/CreditApplicationSchema'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'

const AddCoapplicantPage = () => {
  const { id } = useParams()
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [addCoapplicant, isPending, isError] = usePostCoapplicant()
  const [creditApplication, isLoadingCreditApp] = useCreditApplicationById(id!, false) // if true, poll every 5 secs

  const {
    register,
    handleSubmit,
    watch,
    control,
    setValue,
    getValues,
    trigger,
    formState: { errors },
  } = useForm<ApplicantDto>({
    mode: 'onBlur', // déclenche les validations Après que l'usager ait quitté le champ
    defaultValues: FullApplicantSchema.getDefault(),
    resolver: yupResolver(FullApplicantSchema),
  })

  const onSave = async (data: Partial<ApplicantDto>) => {
    const postParam: PostCoapplicantParams = {
      creditApplicationId: id!,
      coApplicant: data as ApplicantDto,
    }

    await addCoapplicant(postParam).then(() => {
      navigate(`/credit/${id}/view`)
    })
  }

  return (
    <div style={{ height: '100%' }}>
      <PageSpinner isLoading={isPending || isLoadingCreditApp} withBackdrop />

      {creditApplication && (
        <Paper>
          <form onSubmit={handleSubmit(onSave, reportErrorToConsole)} style={{ margin: 5 }}>
            <Typography
              variant="h3"
              component="h2"
              sx={{
                textTransform: 'uppercase',
                textAlign: 'center',
                marginY: 4, // Augmentez l'espace autour si nécessaire
                fontWeight: 'bold', // Rend le texte en gras
              }}
            >
              {t('viewCreditApplication.coApplicant').toUpperCase()}
            </Typography>

            <ApplicantParameters
              register={register}
              watch={watch}
              setValue={setValue}
              getValues={getValues}
              trigger={trigger}
              errors={errors}
              formControl={control}
              editDisabled={false}
              prohibitedPhone={creditApplication?.applicant.cellPhone ?? ''}
            />

            <Grid container mb={5} justifyContent="flex-end">
              <Grid
                item
                xs={2}
                display="flex"
                justifyContent="flex-end"
                paddingRight={1}
                sx={{ marginTop: '5%', marginBottom: '1%' }}
              >
                <AsyncActionButton
                  sx={{ marginTop: 2 }}
                  variant="contained"
                  color="error"
                  onClick={() => {
                    navigate(`/credit/${id}/view`)
                  }}
                >
                  {t('common.cancel')}
                </AsyncActionButton>
              </Grid>

              <Grid
                item
                xs={2}
                display="flex"
                justifyContent="flex-start"
                paddingLeft={1}
                sx={{ marginTop: '5%', marginBottom: '1%', marginRight: '1%' }}
              >
                <AsyncActionButton
                  sx={{ marginTop: 2 }}
                  type="submit"
                  variant="contained"
                  isPending={isPending}
                  isError={isError}
                >
                  {t('common.save')}
                </AsyncActionButton>
              </Grid>
            </Grid>
          </form>
        </Paper>
      )}
    </div>
  )
}
export default AddCoapplicantPage
