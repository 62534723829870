import { Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Box } from '@mui/material'
import { Close } from '@mui/icons-material'
import { useTranslation } from 'react-i18next'
import {
  ApplicantDto,
  EditApplicantContactInfoDtoSchema,
  EditApplicantContactInfoDto,
} from '@src/types/CreditApplicationSchema'
import InputTextField from '@src/components/InputTextField'
import AsyncActionButton from '@src/components/AsyncActionButton'
import { useUpdateApplicantContactInfo } from '@src/data/api/credit-api/credit-api'

import { yupResolver } from '@hookform/resolvers/yup'
import { Controller, useForm } from 'react-hook-form'
import InputTextFieldWithMask from '@src/components/InputMask'
import { memo, useCallback } from 'react'
import { EApplicantType, ELanguageList } from '@src/types/Constants'
import { reportErrorToConsole } from '@src/services/error-logger'
import SelectComponent from '@src/components/SelectComponent'

type Props = {
  open: boolean
  handleClose: () => void
  applicant: ApplicantDto
  creditApplicationId: string
  applicantType: EApplicantType
  prohibitedPhone?: string | null
}

const EditApplicantDialog = ({
  open,
  handleClose,
  applicant,
  creditApplicationId,
  applicantType,
  prohibitedPhone,
}: Props) => {
  const { t } = useTranslation()
  const {
    register,
    handleSubmit,
    setValue,
    control,
    formState: { errors },
  } = useForm<EditApplicantContactInfoDto>({
    mode: 'onBlur',
    resolver: yupResolver(EditApplicantContactInfoDtoSchema),
    defaultValues: { ...applicant, cellPhone: applicant.cellPhone ?? '', email: applicant.email ?? '' },
  })

  const [updateApplicantContactInfo, isPending, isError] = useUpdateApplicantContactInfo()

  const handlePhoneOnchange = (event: React.FocusEvent<HTMLInputElement>) => {
    const { value } = event.target
    const phone = value.replace(/-/g, '')
    event.target.setCustomValidity('')

    if (applicantType === EApplicantType.Coapplicant && prohibitedPhone === phone) {
      event.target.setCustomValidity(t('common.errors.coappSameMobileError'))
    } else if (applicantType === EApplicantType.Applicant && prohibitedPhone === phone) {
      event.target.setCustomValidity(t('common.errors.applicantSameMobileError'))
    }
    setValue(`cellPhone`, phone, { shouldValidate: true })
  }

  const onSubmit = useCallback(
    async (data: EditApplicantContactInfoDto) => {
      if (
        applicant.email !== data.email ||
        applicant.cellPhone !== data.cellPhone ||
        applicant.languageId !== data.languageId
      ) {
        const applicantToSend = {
          ...data,
          creditApplicationId,
          applicantType,
        }

        await updateApplicantContactInfo(applicantToSend)
      }
      handleClose()
    },
    [applicant, applicantType, creditApplicationId, handleClose, updateApplicantContactInfo],
  )

  return (
    <form onSubmit={handleSubmit(onSubmit, reportErrorToConsole)}>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="md"
        keepMounted
        transitionDuration={0}
        disablePortal
      >
        <DialogTitle id="alert-dialog-title" sx={{ textAlign: 'center' }}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              borderBottom: '1px solid',
              pb: 2,
            }}
          >
            {t('common.editApplicant')}
            <IconButton aria-label="close" onClick={handleClose}>
              <Close />
            </IconButton>
          </Box>
        </DialogTitle>
        <DialogContent sx={{ pb: 2 }}>
          <Box sx={{ mb: 2, marginTop: '1rem' }}>
            <InputTextField
              label={t('common.email')}
              fullWidth
              {...register('email')}
              error={errors.email}
              inputProps={{ maxLength: 254 }}
            />
          </Box>
          <Controller
            name="cellPhone"
            control={control}
            render={() => (
              <InputTextFieldWithMask
                defaultValue={applicant.cellPhone}
                mask="000-000-0000"
                placeholder="( ___ ) ___-____"
                label={t('common.phone')}
                fullWidth
                onInput={handlePhoneOnchange}
                error={errors.cellPhone}
              />
            )}
          />
          <Box sx={{ mb: 2, marginTop: '1rem' }}>
            <SelectComponent
              valueList={ELanguageList.map((value) => ({ label: `language.${value}`, value }))}
              label={t('editCreditApplication.information.languageId') as string}
              {...register(`languageId`)}
              error={errors?.languageId}
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            <AsyncActionButton variant="outlined" type="submit" isPending={isPending} isError={isError}>
              {t('profile.updateInformation')}
            </AsyncActionButton>
          </Box>
        </DialogActions>
      </Dialog>
    </form>
  )
}

export default memo(EditApplicantDialog)
