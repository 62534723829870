import React from 'react'
import { BrowserRouter as Router } from 'react-router-dom'
import { Container, CssBaseline, ThemeProvider } from '@mui/material'
import { AppContext, useAppStore } from '@src/contexts/AppContext'
import { ThemeContext, useThemeStore } from '@src/contexts/ThemeContext'
import TopLevelRoutes from './Routes'
import LoginPage from '../Authentication/LoginPage'
import AppHeader from './components/AppHeader'
import AlertBannerOnDeprecatedStatus from './components/AlertBannerOnDeprecatedStatus'

const App = () => {
  const appStore = useAppStore()
  const themeStore = useThemeStore()
  const { pathname } = window.location

  const isAuthenticate = pathname === '/auth-page'
  const needAuth = !appStore.user

  return (
    <ThemeProvider theme={themeStore.theme}>
      <AppContext.Provider value={appStore}>
        <ThemeContext.Provider value={themeStore}>
          <CssBaseline />
          <Router>
            {needAuth && !isAuthenticate && <LoginPage />}
            {!needAuth && (
              <>
                <AlertBannerOnDeprecatedStatus />
                <AppHeader />
                <Container maxWidth="xl" disableGutters sx={{ mt: 3, mb: 10, overflow: 'auto' }}>
                  <TopLevelRoutes />
                </Container>
              </>
            )}
          </Router>
        </ThemeContext.Provider>
      </AppContext.Provider>
    </ThemeProvider>
  )
}

export default App
