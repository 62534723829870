import { CreditApplication } from '@src/types/CreditApplicationSchema'
import { CvtAwaitingApproval } from '@src/data/creditapp-selectors'
import { EOriginSystemId } from '@src/types/Constants'
import SignManuallyButton from './SignManuallyButton'
import CreateContractButton from './CreateContractButton'
import SignDigitallyButton from './SignDigitallyButton'
import ContractInformation from './ContractInformation'

type Props = {
  creditApp: CreditApplication
}
const ContractAwaitingSignature = ({ creditApp }: Props) => {
  return (
    <>
      <ContractInformation creditApp={creditApp} />
      {creditApp.originSystemId === EOriginSystemId.MerchantDashboard && (
        <>
          <SignDigitallyButton creditApp={creditApp} />
          <SignManuallyButton creditApp={creditApp} />
          {!CvtAwaitingApproval(creditApp.requiredDocuments) && <CreateContractButton creditApp={creditApp} />}
        </>
      )}
    </>
  )
}

export default ContractAwaitingSignature
