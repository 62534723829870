export function getStringArrayFromQueryString(
  urlParams: URLSearchParams,
  paramName: string,
  defaultValue: string[] = [],
): string[] {
  const value = decodeURIComponent(urlParams.get(paramName) ?? '')
  return !value ? defaultValue : value.split(',')
}

export function getNumberArrayFromQueryString(
  urlParams: URLSearchParams,
  paramName: string,
  defaultValue: number[] = [],
): number[] {
  const value = decodeURIComponent(urlParams.get(paramName) ?? '')
  return !value ? defaultValue : value.split(',').map((x) => +x)
}

export function getNumberFromQueryString(urlParams: URLSearchParams, paramName: string, defaultValue: number): number {
  const value = decodeURIComponent(urlParams.get(paramName) ?? '')
  return !value ? defaultValue : +value
}

export function getStringFromQueryString(urlParams: URLSearchParams, paramName: string, defaultValue = ''): string {
  return decodeURIComponent(urlParams.get(paramName) ?? defaultValue)
}

export function getBooleanFromQueryString(
  urlParams: URLSearchParams,
  paramName: string,
  defaultValue = false,
): boolean {
  const value = urlParams.get(paramName)
  const decodedValue = decodeURIComponent(value!).toLowerCase()
  if (decodedValue === 'true') {
    return true
  }
  return defaultValue
}

export const serializeParameter = (params: Record<string, string | number | string[] | number[]> | undefined) => {
  const searchParams = new URLSearchParams()
  if (params)
    Object.keys(params).forEach((key) => {
      const v = params[key]
      if (v.toString()) searchParams.append(key, v.toString())
    })
  return searchParams.toString()
}
