import { Box, Divider, Typography } from '@mui/material'
import { useFundingSummaryById } from '@src/data/api/credit-api/credit-api'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { EFinancingProgram } from '@src/types/Constants'
import PageSpinner from '../../components/PageSpinner'
import ClientInformation from './components/ClientInformation'
import FundingSummaryButtonGroup from './components/FundingSummaryButtonGroup'
import LoanInformation from './components/LoanInformation'
import ServiceProviderInformation from './components/ServiceProviderInformation'

const FundingSummary = () => {
  const { financingProgramId, id } = useParams<{ id: string; financingProgramId: EFinancingProgram }>()
  const { t } = useTranslation()

  const [fundingSummary, isLoadingSummary] = useFundingSummaryById(id!, financingProgramId!)

  const divider = (
    <Divider
      sx={{
        backgroundColor: '#08ABF0',
        borderBottomWidth: 2.5,
        marginTop: '5%',
        marginBottom: '5%',
      }}
    />
  )

  if (isLoadingSummary) return <PageSpinner isLoading withBackdrop />

  return (
    <Box
      sx={{
        marginLeft: 'auto',
        marginRight: 'auto',
        width: '75%',
        maxWidth: '100%',
        '@media print': {
          width: '100%',
        },
      }}
    >
      <Typography variant="h3" align="center" gutterBottom>
        {t('fundingSummary.fundingConfirmation')}
      </Typography>
      {divider}
      <LoanInformation fundingSummary={fundingSummary} />
      {divider}
      <ServiceProviderInformation fundingSummary={fundingSummary} />
      <ClientInformation fundingSummary={fundingSummary} />
      {divider}
      <Typography variant="h6" align="center" gutterBottom>
        {t('fundingSummary.contactUsIfBankDetailsChanged')}
      </Typography>
      <FundingSummaryButtonGroup />
    </Box>
  )
}

export default FundingSummary
