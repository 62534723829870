import { Address } from '@src/types/AddressSchema'
import { EFinancingProgram, ERelationToApplicant } from '@src/types/Constants'
import { ApplicantJob, ApplicantOtherIncome, CreditApplication } from '@src/types/CreditApplicationSchema'
import { sumBy } from 'lodash-es'

function strCompare(param1: string | null | undefined, param2: string | null | undefined): boolean {
  return (param1 ?? '') === (param2 ?? '')
}

function DoesApplicantLiveWithCoapplicant(application: CreditApplication): boolean {
  const applicantAddress = application.applicant.currentAddress as Address
  const coApplicantAddress = application.coApplicant?.currentAddress as Address

  return (
    strCompare(applicantAddress.civicNumber, coApplicantAddress.civicNumber) &&
    strCompare(applicantAddress.apartment, coApplicantAddress.apartment) &&
    strCompare(applicantAddress.street, coApplicantAddress.street) &&
    strCompare(applicantAddress.city, coApplicantAddress.city) &&
    strCompare(applicantAddress.country, coApplicantAddress.country) &&
    strCompare(applicantAddress.stateIso, coApplicantAddress.stateIso) &&
    strCompare(applicantAddress.postalCode, coApplicantAddress.postalCode)
  )
}

export type BusinessError = {
  message: string
  params?: Record<string, string | number>
}

export function GetMinIncomeForProgram(program: EFinancingProgram): number {
  if (EFinancingProgram.Vlvr === program) return 20000
  return 21000
}

export function GetMinCombinedIncomeForProgram(program: EFinancingProgram): number {
  if (EFinancingProgram.Vlvr === program) return 35000
  return 35000
}

export function validateCreditApplicationCanBeSubmittedForReview(application: CreditApplication): BusinessError[] {
  const ret: BusinessError[] = []
  const applicantIncomes =
    Number(sumBy(application.applicant.currentJobs, (x: ApplicantJob) => x.annualSalary)) +
    Number(sumBy(application.applicant.otherIncomes, (x: ApplicantOtherIncome) => x.annualAmount))

  const coapplicantIncomes =
    Number(sumBy(application.coApplicant?.currentJobs, (x: ApplicantJob) => x.annualSalary)) +
    Number(sumBy(application.coApplicant?.otherIncomes, (x: ApplicantOtherIncome) => x.annualAmount))

  const minIncome = GetMinIncomeForProgram(application.financingProgramId)
  const minCombinedIncome = GetMinCombinedIncomeForProgram(application.financingProgramId)
  const coapplicantIsSpouse =
    application.coApplicant?.relationWithApplicant === ERelationToApplicant.Spouse ||
    application.coApplicant?.relationWithApplicant === ERelationToApplicant.CommonLaw

  if (coapplicantIsSpouse && DoesApplicantLiveWithCoapplicant(application)) {
    if (
      applicantIncomes < minIncome &&
      coapplicantIncomes < minIncome &&
      applicantIncomes + coapplicantIncomes < minCombinedIncome
    )
      ret.push({
        message: 'editCreditApplication.errors.minIncomeNotMetCouple',
        params: { minIncome, minCombinedIncome },
      })
  } else if (applicantIncomes < minIncome && coapplicantIncomes < minIncome) {
    ret.push({ message: 'editCreditApplication.errors.minIncomeNotMet', params: { minIncome } })
  }
  return ret
}
