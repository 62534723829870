import { TextField, InputBaseComponentProps, useTheme } from '@mui/material'
import React from 'react'
import { FieldError } from 'react-hook-form'
import { SelectValueListItem } from '@src/types/SelectValueListItem'
import translate from '@src/services/translate'
import translateErrorCode from './TranslateError'

interface Props extends InputBaseComponentProps {
  error?: FieldError
  valueList: Array<SelectValueListItem>
  label?: string
}

const SelectComponent = React.forwardRef<HTMLSelectElement, Props>(function SelectComponent(
  { error, valueList, label, ...otherProps }: Props,
  ref,
) {
  const theme = useTheme()

  return (
    <TextField
      inputRef={ref}
      label={label}
      error={error !== undefined}
      helperText={error ? translateErrorCode(error) : ' '}
      variant="outlined"
      fullWidth
      select
      InputLabelProps={{ shrink: true }}
      SelectProps={{
        native: true,
        inputProps: { ...otherProps },
      }}
    >
      <option aria-label="None" value="" style={{ display: 'none' }} hidden />
      {valueList.map((item) => (
        <option
          style={{
            color: item.label.toString().includes('**') ? theme.palette.grey[500] : 'none',
          }}
          key={item.value}
          value={item.value}
        >
          {translate(item.label)}
        </option>
      ))}
    </TextField>
  )
})

SelectComponent.defaultProps = {
  error: undefined,
}
export default SelectComponent
