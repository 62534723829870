import { ButtonProps } from '@mui/material'
import AsyncActionButton from '@src/components/AsyncActionButton'
import { useDownloadSignedContractPdf } from '@src/data/api/credit-api/files-api'
import { CreditApplication } from '@src/types/CreditApplicationSchema'
import { useTranslation } from 'react-i18next'

interface Props extends ButtonProps {
  creditApp: CreditApplication
}

const DownloadContractButton = ({ creditApp }: Props) => {
  const { t } = useTranslation()
  const [downloadPdf, isDownloading, isError, isSuccess] = useDownloadSignedContractPdf(
    creditApp.financingProgramId,
    creditApp.id,
  )

  return (
    <AsyncActionButton
      fullWidth
      variant="contained"
      isPending={isDownloading}
      isError={isError}
      isSuccess={isSuccess}
      onClick={() => downloadPdf(creditApp.contract.cvtNumber)}
    >
      {t('worksheet.download')}
    </AsyncActionButton>
  )
}

export default DownloadContractButton
