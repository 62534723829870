import { Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { EFinancingProgram } from '@src/types/Constants'
import FundingSummaryButton from './FundingSummaryButton'

type Props = {
  id: string
  financingProgramId: EFinancingProgram
}

const FundingLoanCompleted = ({ id, financingProgramId }: Props) => {
  const { t } = useTranslation()
  return (
    <div>
      <Typography variant="body1">{t('viewCreditApplication.fundingCompleted')}</Typography>
      <br />
      <br />
      <FundingSummaryButton id={id} financingProgramId={financingProgramId} />
    </div>
  )
}

export default FundingLoanCompleted
