import { Merchant } from '@src/types/Merchant'
import { debounce } from 'lodash-es'
import { useCallback, useEffect, useState } from 'react'

export function useMerchantsFilter(merchants: Merchant[], initialQuery = '') {
  const [query, setQuery] = useState(initialQuery)
  const [filteredMerchants, setFilteredMerchants] = useState<Merchant[]>([])

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debounceFilter = useCallback(
    debounce((q: string) => {
      const filtered = merchants.filter((merchant) => merchant.name.toLowerCase().includes(q.toLowerCase()))
      setFilteredMerchants(filtered)
    }, 300),
    [merchants, setFilteredMerchants],
  )

  useEffect(() => {
    if (query) {
      debounceFilter(query)
    } else {
      setFilteredMerchants(merchants)
    }

    return () => debounceFilter.cancel()
  }, [query, debounceFilter, merchants])

  return { filteredMerchants, query, setQuery }
}

export function useMerchantSelection(merchantsSelected: Merchant[]) {
  const [selectedMerchants, setSelectedMerchants] = useState<Merchant[]>([])

  useEffect(() => {
    if (merchantsSelected) setSelectedMerchants(merchantsSelected)
  }, [merchantsSelected])

  const handleItemChecked = useCallback((merchant: Merchant, isChecked: boolean) => {
    setSelectedMerchants((prevSelected) => {
      const isAlreadySelected = prevSelected.some((m) => m.id === merchant.id)
      if (isChecked && !isAlreadySelected) {
        return [...prevSelected, merchant]
      }
      if (!isChecked && isAlreadySelected) {
        return prevSelected.filter((m) => m.id !== merchant.id)
      }
      return prevSelected
    })
  }, [])

  return { selectedMerchants, setSelectedMerchants, handleItemChecked }
}
