import { Link, Stack } from '@mui/material'
import { GridColDef } from '@mui/x-data-grid'
import { FormatCurrencyNoDecimals, FormatCurrencyNoDecimalsNoZero, formatIsoDate } from '@src/services/Formatter'
import {
  getBooleanFromQueryString,
  getNumberFromQueryString,
  getStringArrayFromQueryString,
  getStringFromQueryString,
} from '@src/services/query-string'
import { ECreditApplicationStatus, EOriginSystemId, EPaymentPlan } from '@src/types/Constants'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { CreditDashboardEntry } from '@src/types/CreditDashboardEntry'
import { getEndDate, getStartDate } from './browseCreditApplicationPage-selector'
import CreditApplicationProgressStatusBar from './components/CreditApplicationProgressStatusBar'
import MerchantNotificationIcons from './components/MerchantNotificationIcons'

export function useCreditDashboardEntriesColumns(isVeterinary: boolean, canSkipFlinks: boolean) {
  const { t } = useTranslation()
  // Table def
  const columns = useMemo<GridColDef<CreditDashboardEntry>[]>(
    () => [
      {
        field: 'creditApplicationReferenceNumber',
        headerName: t('applicationHeader.applicationNumber'),
        description: t('applicationHeader.applicationNumber'),
        flex: 1,
        headerAlign: 'center',
        align: 'center',
        renderCell: (params) => {
          if (params.row.creditApplicationStatus === ECreditApplicationStatus.Draft)
            return (
              <Link href={`/credit/${params.row.id}/edit`} underline="none">
                {params.row.creditApplicationReferenceNumber}
              </Link>
            )
          return (
            <Link href={`/credit/${params.row.id}/view`} underline="none">
              {params.row.creditApplicationReferenceNumber}
            </Link>
          )
        },
      },
      {
        field: 'merchantName',
        headerName: t('applicationHeader.merchant'),
        description: t('applicationHeader.merchant'),
        flex: 2,
        headerAlign: 'left',
        align: 'left',
      },
      {
        field: 'clientName',
        headerName: t('applicationHeader.name'),
        description: t('applicationHeader.name'),
        flex: 2,
        headerAlign: 'left',
        align: 'left',
        display: 'flex',
        alignItems: 'center',
        renderCell: (params) => (
          <div style={{ textAlign: 'left' }}>
            {params.row.clientName}
            {params.row.beneficiaryName && (
              <p style={{ margin: '0', fontSize: 'smaller', fontWeight: 'lighter', color: 'darkGray' }}>
                {isVeterinary
                  ? `${t('common.petName')}: ${params.row.beneficiaryName}`
                  : `${t('common.beneficiary')}: ${params.row.beneficiaryName}`}
              </p>
            )}
          </div>
        ),
      },
      {
        field: 'maxAmountFinanced',
        headerName: t('applicationHeader.approvedLimit'),
        description: t('applicationHeader.approvedLimit'),
        flex: 1,
        headerAlign: 'center',
        align: 'center',
        valueFormatter: (value) => FormatCurrencyNoDecimals(value),
      },
      {
        field: 'interestRate',
        headerName: t('applicationHeader.rate'),
        description: t('applicationHeader.rate'),
        flex: 1,
        headerAlign: 'center',
        align: 'center',
        renderCell: (params) => {
          let formattedValue = params.value !== undefined ? `${params.value}` : ''
          if (formattedValue === '0' || params.row.originSystemId !== EOriginSystemId.MerchantDashboard) {
            return ''
          }
          if (params.row.paymentPlanId !== EPaymentPlan.regularDailyInterests) {
            return 'PROMO'
          }
          formattedValue += ' %'

          return formattedValue
        },
      },
      {
        field: 'amountRequested',
        headerName: `${t('applicationHeader.loan')}`,
        description: t('applicationHeader.loan'),
        flex: 1,
        headerAlign: 'center',
        align: 'center',
        valueFormatter: (value) => FormatCurrencyNoDecimalsNoZero(value),
      },
      {
        field: 'status',
        headerName: t('applicationHeader.status'),
        description: t('applicationHeader.status'),
        flex: 1.75,
        headerAlign: 'left',
        align: 'left',
        sortable: false,
        renderCell: (params) => {
          return (
            <Stack direction="column" spacing={0.5}>
              <CreditApplicationProgressStatusBar
                status={params.row.progressionStatus}
                prequalDecision={params.row.prequalificationDecision}
                hardHitDecision={params.row.hardHitDecision}
                incomeConfirmed={params.row.incomeConfirmed}
                isContractSignatureMethodSelected={params.row.isContractSignatureMethodSelected}
                creditApplicationStatus={params.row.creditApplicationStatus}
                currentCustomerStep={params.row.currentCustomerStep}
                missingRequiredDocuments={params.row.missingRequiredDocuments}
                originSystemId={params.row.originSystemId}
                canSkipFlinks={canSkipFlinks}
              />
            </Stack>
          )
        },
      },
      {
        field: 'createdOn',
        headerName: t('applicationHeader.date'),
        description: t('applicationHeader.date'),
        flex: 1.25,
        headerAlign: 'left',
        align: 'left',
        display: 'flex',
        alignItems: 'center',
        renderCell: (params) => (
          <div style={{ textAlign: 'left' }}>
            {params.row.createdOn && (
              <p style={{ margin: '0', fontSize: 'small' }}>
                {t('applicationHeader.dateCreated')}: {formatIsoDate(params.row.createdOn)}
              </p>
            )}
            {params.row.expiresOn && (
              <p style={{ margin: '0', fontSize: 'smaller', fontWeight: 'lighter', color: 'darkGray' }}>
                {t('applicationHeader.validUntil')}: {formatIsoDate(params.row.expiresOn)}
              </p>
            )}
          </div>
        ),
      },
      {
        field: 'Action',
        headerName: 'Action',
        description: 'Action',
        flex: 0.75,
        headerAlign: 'left',
        align: 'left',
        renderCell: (params) => {
          return (
            <div>
              <MerchantNotificationIcons
                merchantNotificationCodes={params.row.merchantNotificationCodes}
                requiredDocuments={params.row.missingRequiredDocuments}
                originSystemId={params.row.originSystemId}
              />
            </div>
          )
        },
      },
    ],
    [t, isVeterinary, canSkipFlinks],
  )

  return columns
}

export function useCreditDashboardEntriesQuery(searchParams: URLSearchParams) {
  const query = useMemo(() => {
    const searchValue = getStringFromQueryString(searchParams, 'searchValue', '')
    const searchValueIsNum = !Number.isNaN(Number(searchValue))
    return {
      limit: getNumberFromQueryString(searchParams, 'limit', 25),
      offset: getNumberFromQueryString(searchParams, 'offset', 0),
      financingProgramIds: getStringArrayFromQueryString(searchParams, 'financingProgramIds'),
      territoryIds: getStringArrayFromQueryString(searchParams, 'territoryIds'),
      creditApplicationStatus: getStringFromQueryString(
        searchParams,
        'creditApplicationStatus',
        ECreditApplicationStatus.Active,
      ) as ECreditApplicationStatus,
      clientNameContains: searchValueIsNum ? '' : searchValue,
      referenceNumber: searchValueIsNum ? searchValue : '',
      merchantId: getStringFromQueryString(searchParams, 'merchantId', ''),
      dateRange: getStringFromQueryString(searchParams, 'dateRange', ''),
      plan: getStringArrayFromQueryString(searchParams, 'plan'),
      progressionStatus: getStringArrayFromQueryString(searchParams, 'progressionStatus'),
      startDate: getStringFromQueryString(searchParams, 'startDate', getStartDate()),
      endDate: getStringFromQueryString(searchParams, 'endDate', getEndDate()),
      currentCustomerStep: getStringFromQueryString(searchParams, 'currentCustomerStep', ''),
      requiresMerchantAttention: getBooleanFromQueryString(searchParams, 'requiresMerchantAttention'),
      originSystemId: getStringFromQueryString(searchParams, 'originSystemId', ''),
      createdByUserId: getStringFromQueryString(searchParams, 'createdByUserId', ''),
    }
  }, [searchParams])

  return query
}
