import { CreditApplication, DraftCreditApplicationSchema } from '@src/types/CreditApplicationSchema'
import { useState } from 'react'
import { useMerchantById } from '@src/data/api/merchants-api/merchants-api'
import { useUser } from '@src/contexts/AppContext'
import { useSaveCreditApplicationDraft } from '@src/data/api/credit-api/credit-api'
import { useNavigate } from 'react-router-dom'
import { ECreditApplicationStatus } from '@src/types/Constants'
import CreditApplicationForm from './components/CreditApplicationForm'

const CreateCreditApplicationPage = (): JSX.Element => {
  const user = useUser()

  const [merchant] = useMerchantById(user?.merchantId ?? '')
  const [creditApplication] = useState<CreditApplication>({
    ...(DraftCreditApplicationSchema.getDefault() as unknown as CreditApplication),
  })
  const navigate = useNavigate()
  const [saveCreditAppMutation, isPending, isError, reset, error] = useSaveCreditApplicationDraft()

  const onSave = async (data: Partial<CreditApplication>) => {
    await saveCreditAppMutation(data).then((creditApp) => {
      reset()
      if (creditApp.status === ECreditApplicationStatus.Draft) navigate(`/`)
      else {
        navigate(`/credit/${creditApp.id}/view`)
      }
    })
  }

  return (
    <div>
      <CreditApplicationForm
        merchant={merchant}
        creditApplication={creditApplication}
        onSave={onSave}
        isPending={isPending}
        isError={isError}
        serverErrors={error}
      />
    </div>
  )
}

export default CreateCreditApplicationPage
