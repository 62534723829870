import {
  ApplicantDto,
  CreditApplication,
  DraftCreditApplicationDto,
  EditApplicantContactInfoDto,
} from '@src/types/CreditApplicationSchema'
import {
  MutationFunction,
  QueryFunction,
  QueryFunctionContext,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query'
import { RequiredDocument } from '@src/types/RequiredDocument'
import { formatDate, formatUtcIsoDateTime } from '@src/services/Formatter'
import { addDays, parseISO } from 'date-fns'
import { EApplicantType, ECreditApplicationStatus, EFinancingProgram, EServiceCategory } from '@src/types/Constants'

import { AxiosError } from 'axios'
import { serializeParameter } from '../../../services/query-string'
import { CreditDashboardEntry } from '../../../types/CreditDashboardEntry'
import { getApiClient } from '../api-client'
import { UploadFilesDto, UploadFilesResultDto, uploadRequiredDocument } from './files-api'

import {
  transformApplicantToApi,
  transformCreditApplicationFromApi,
  transformCreditApplicationToApi,
} from './credit-transform'

const SCOPE = 'credit-applications'
const SUMMARY = 'funding-summary'
const LIST = 'list'
const DETAIL = 'detail'
const SKIPBANKREQUEST = 'skip-bank-request-requirements'

const fiveMinutes = 300000
const oneMinute = 60000

export interface CreditDashboardFilters {
  financingProgramIds: string[]
  progressionStatus: string[]
  territoryIds: string[]
  plan: string[]
  dateRange: string
  creditApplicationStatus: string
  clientNameContains: string
  referenceNumber: string
  merchantId: string
  limit: number
  offset: number
  startDate: string
  endDate: string
  currentCustomerStep: string
  requiresMerchantAttention: boolean
  originSystemId: string
  createdByUserId: string
}

export interface BankAccountRequestDto {
  applicantType: EApplicantType
  creditApplicationId: string
}

const keysFactory = {
  all: () => [{ scope: SCOPE }] as const,
  allLists: () => [{ scope: SCOPE, entity: LIST }] as const,
  list: (filters: CreditDashboardFilters) => [{ scope: SCOPE, entity: LIST, ...filters }] as const,
  allDetails: () => [{ scope: SCOPE, entity: DETAIL }] as const,
  detail: (id: string) => [{ scope: SCOPE, entity: DETAIL, id }] as const,
  allSummary: () => [{ scope: SUMMARY, entity: DETAIL }] as const,
  summary: (id: string) => [{ scope: SUMMARY, entity: DETAIL, id }] as const,
  allSkipBankRequestRequirements: () => [{ scope: SKIPBANKREQUEST, entity: DETAIL }] as const,
  skipBankAccountRequestRequirements: (id: string) => [{ scope: SKIPBANKREQUEST, entity: DETAIL, id }] as const,
}

export function transformDateFilterToApi(filters: CreditDashboardFilters) {
  const newFilters = { ...filters }
  newFilters.startDate = formatUtcIsoDateTime(parseISO(filters.startDate))
  newFilters.endDate = formatUtcIsoDateTime(addDays(parseISO(filters.endDate), 1))
  return newFilters
}

const getCreditApplicationList = async ({
  queryKey: [filters],
}: QueryFunctionContext<ReturnType<(typeof keysFactory)['list']>>) => {
  const apiClient = getApiClient()
  const response = await apiClient.get<CreditDashboardEntry[]>('/creditApplication', {
    params: transformDateFilterToApi(filters),
    paramsSerializer(params) {
      return serializeParameter(params)
    },
  })
  return response.data
}

export function useCreditApplicationList(filter: CreditDashboardFilters): [CreditDashboardEntry[], boolean] {
  const { isFetching, data } = useQuery({
    queryKey: [...keysFactory.list(filter)],
    queryFn: getCreditApplicationList,
    placeholderData: [],
    staleTime: oneMinute,
    gcTime: oneMinute,
    refetchOnWindowFocus: 'always',
  })

  return [data ?? [], isFetching]
}

const saveCreditApplication: MutationFunction<CreditApplication, Partial<DraftCreditApplicationDto>> = async (
  creditApplication,
) => {
  const apiClient = getApiClient()
  const response = await apiClient.post<CreditApplication>(
    '/creditApplication',
    transformCreditApplicationToApi(creditApplication),
  )
  return response.data
}

export function useSaveCreditApplicationDraft(): [
  MutationFunction<CreditApplication, Partial<DraftCreditApplicationDto>>,
  boolean,
  boolean,
  () => void,
  AxiosError | null,
] {
  const queryClient = useQueryClient()
  const { mutateAsync, isPending, isError, reset, error } = useMutation({
    mutationFn: saveCreditApplication,
    onSuccess: async (data) => {
      await queryClient.invalidateQueries({ queryKey: keysFactory.detail(data.id) })
      return data
    },
  })

  return [mutateAsync, isPending, isError, reset, error as AxiosError]
}

const editCreditApplication: MutationFunction<CreditApplication, Partial<DraftCreditApplicationDto>> = async (
  creditApplication,
) => {
  const apiClient = getApiClient()
  const transformedCreditApplication = transformCreditApplicationToApi(creditApplication)

  const response = await apiClient.put<CreditApplication>(
    `/creditApplication/${encodeURIComponent(transformedCreditApplication.financingProgramId!)}/${encodeURIComponent(
      transformedCreditApplication.id!,
    )}`,
    transformedCreditApplication,
  )
  response.data = transformCreditApplicationFromApi(response.data)
  return response.data
}

export function useEditCreditApplicationDraft(): [
  MutationFunction<CreditApplication, Partial<DraftCreditApplicationDto>>,
  boolean,
  boolean,
  () => void,
  AxiosError | null,
] {
  const queryClient = useQueryClient()
  const { mutateAsync, isPending, isError, reset, error } = useMutation({
    mutationFn: editCreditApplication,
    onSuccess: async (data) => {
      queryClient.setQueryData(keysFactory.detail(data.id), data)
      if (data.status !== ECreditApplicationStatus.Draft)
        await queryClient.invalidateQueries({ queryKey: keysFactory.allLists() })
      return data
    },
  })

  return [mutateAsync, isPending, isError, reset, error as AxiosError]
}

const getCreditApplicationById = async ({
  queryKey: [{ id }],
}: QueryFunctionContext<ReturnType<(typeof keysFactory)['detail']>>) => {
  const apiClient = getApiClient()
  const response = await apiClient.get(`/CreditApplication/${encodeURIComponent(id)}`)
  const creditAppResponse = response.data as CreditApplication

  if (creditAppResponse.worksheet && creditAppResponse.worksheet.firstPaymentOn !== null)
    creditAppResponse.worksheet.firstPaymentOn = formatDate(creditAppResponse.worksheet.firstPaymentOn)
  return creditAppResponse
}

export function useCreditApplicationById(
  creditApplicationId: string,
  shouldPoll: boolean,
): [CreditApplication, boolean] {
  const { isFetching, data } = useQuery({
    queryKey: keysFactory.detail(creditApplicationId),
    queryFn: getCreditApplicationById,
    enabled: !!creditApplicationId,
    staleTime: fiveMinutes,
    gcTime: fiveMinutes,
    refetchInterval: shouldPoll ? 5000 : undefined,
  })

  return [data!, isFetching]
}

const getDraftCreditApplicationById = async ({
  queryKey: [{ id }],
}: QueryFunctionContext<ReturnType<(typeof keysFactory)['detail']>>) => {
  const apiClient = getApiClient()
  const response = await apiClient.get<CreditApplication>(
    `/CreditApplication/${EFinancingProgram.Personal}/${encodeURIComponent(id)}/Draft`,
  )
  const creditAppResponse = transformCreditApplicationFromApi(response.data)
  return creditAppResponse
}

export function useDraftCreditApplicationById(creditApplicationId: string): [CreditApplication, boolean] {
  const { isFetching, data } = useQuery({
    queryKey: keysFactory.detail(creditApplicationId),
    queryFn: getDraftCreditApplicationById,
    enabled: !!creditApplicationId,
    staleTime: fiveMinutes,
    gcTime: fiveMinutes,
  })

  return [data!, isFetching]
}

const updateApplicantContactInfo: MutationFunction<CreditApplication, EditApplicantContactInfoDto> = async (
  applicantInfo: EditApplicantContactInfoDto,
) => {
  const apiClient = getApiClient()
  const response = await apiClient.put<CreditApplication>(
    `/CreditApplication/${EFinancingProgram.Personal}/${encodeURIComponent(
      applicantInfo.creditApplicationId!,
    )}/Applicant`,
    applicantInfo,
  )
  return response.data
}

export function useUpdateApplicantContactInfo(): [
  MutationFunction<CreditApplication, EditApplicantContactInfoDto>,
  boolean,
  boolean,
  () => void,
] {
  const queryClient = useQueryClient()

  const { mutateAsync, isPending, isError, reset } = useMutation({
    mutationFn: updateApplicantContactInfo,
    onSuccess: (data) => {
      queryClient.setQueryData<CreditApplication>(keysFactory.detail(data.id), data)
    },
  })

  return [mutateAsync, isPending, isError, reset]
}

export function useUploadRequiredDocument(): [
  MutationFunction<UploadFilesResultDto, UploadFilesDto>,
  boolean,
  boolean,
  () => void,
] {
  const queryClient = useQueryClient()
  const { mutateAsync, isPending, isError, reset } = useMutation({
    mutationFn: uploadRequiredDocument,
    onSuccess: async (response) => {
      const updatedCreditApp = {
        ...queryClient.getQueryData(keysFactory.detail(response.creditApplicationId)),
      } as CreditApplication
      const updatedFileIndex = updatedCreditApp.requiredDocuments.findIndex(
        (x) =>
          x.applicantType === response.applicantType && x.typeId === response.typeId && x.subKey === response.subKey,
      )
      if (updatedFileIndex > -1) {
        updatedCreditApp.requiredDocuments = [...updatedCreditApp.requiredDocuments]
        updatedCreditApp.requiredDocuments[updatedFileIndex] = {
          ...updatedCreditApp.requiredDocuments[updatedFileIndex],
          status: response.status,
          receivedOn: response.receivedOn,
        }
        await queryClient.setQueryData(keysFactory.detail(response.creditApplicationId), updatedCreditApp)
      }
    },
  })
  return [mutateAsync, isPending, isError, reset]
}

export interface PostCoapplicantParams {
  creditApplicationId: string
  coApplicant: ApplicantDto
}

const postCoapplicant: MutationFunction<CreditApplication, PostCoapplicantParams> = async ({
  creditApplicationId,
  coApplicant,
}: PostCoapplicantParams) => {
  const apiClient = getApiClient()
  const response = await apiClient.post<CreditApplication>(
    `/CreditApplication/${EFinancingProgram.Personal}/${encodeURIComponent(creditApplicationId)}/coapplicant`,
    transformApplicantToApi(coApplicant),
  )
  return response.data
}

export function usePostCoapplicant(): [
  MutationFunction<CreditApplication, PostCoapplicantParams>,
  boolean,
  boolean,
  () => void,
] {
  const queryClient = useQueryClient()
  const { mutateAsync, isPending, isError, reset } = useMutation({
    mutationFn: postCoapplicant,
    onSuccess: (newData) => {
      queryClient.setQueryData<CreditApplication>(keysFactory.detail(newData.id), newData)
    },
  })

  return [mutateAsync, isPending, isError, reset]
}

const deleteCoapplicant: MutationFunction<CreditApplication, string> = async (creditApplicationId: string) => {
  const apiClient = getApiClient()
  const response = await apiClient.delete<CreditApplication>(
    `/CreditApplication/${EFinancingProgram.Personal}/${encodeURIComponent(creditApplicationId)}/coapplicant`,
  )
  return response.data
}

export function useDeleteCoapplicant(): [MutationFunction<CreditApplication, string>, boolean, () => void] {
  const queryClient = useQueryClient()
  const { mutateAsync, isPending, reset } = useMutation({
    mutationFn: deleteCoapplicant,
    onSuccess: (newData) => {
      queryClient.setQueryData<CreditApplication>(keysFactory.detail(newData.id), (oldData) => {
        return { ...oldData, ...newData }
      })
    },
  })

  return [mutateAsync, isPending, reset]
}

const qualify: MutationFunction<CreditApplication, string> = async (creditApplicationId) => {
  const apiClient = getApiClient()
  const response = await apiClient.post<CreditApplication>(
    `/CreditApplication/${EFinancingProgram.Personal}/${encodeURIComponent(creditApplicationId)}/qualify`,
  )
  return response.data
}

export function useQualify(): [MutationFunction<CreditApplication, string>, boolean, boolean, boolean] {
  const queryClient = useQueryClient()
  const { mutateAsync, isPending, isError, isSuccess } = useMutation({
    mutationFn: qualify,
    onSuccess: async (updatedData) => {
      queryClient.setQueryData(keysFactory.detail(updatedData.id), (existingData: CreditApplication) => {
        // Cherry pick data because BE returns an incomplete filtered application
        return {
          ...existingData,
          consentHardHit: updatedData.consentHardHit,
          versionTag: updatedData.versionTag,
          updatedOn: updatedData.updatedOn,
        }
      })
      await queryClient.invalidateQueries({ queryKey: keysFactory.allLists() })
    },
  })
  return [mutateAsync, isPending, isError, isSuccess]
}

const postStatus: MutationFunction<CreditApplication, { id: string; status: string }> = async ({ id, status }) => {
  const apiClient = getApiClient()
  const response = await apiClient.put(
    `/CreditApplication/${encodeURIComponent(id)}/status/${encodeURIComponent(status)}`,
  )

  return response.data as CreditApplication
}

export function usePostStatus(): [
  MutationFunction<CreditApplication, { id: string; status: string }>,
  boolean,
  boolean,
  boolean,
  () => void,
] {
  const queryClient = useQueryClient()
  const { mutateAsync, isPending, isError, isSuccess, reset } = useMutation({
    mutationFn: postStatus,
    onSuccess: async (updatedData) => {
      queryClient.setQueryData(keysFactory.detail(updatedData.id), (existingData: CreditApplication) => {
        // Cherry pick data because BE returns an incomplete filtered application
        return {
          ...existingData,
          status: updatedData.status,
          versionTag: updatedData.versionTag,
          updatedOn: updatedData.updatedOn,
        }
      })
      await queryClient.invalidateQueries({ queryKey: keysFactory.allLists() })
    },
  })

  return [mutateAsync, isPending, isError, isSuccess, reset]
}

const skipBankAccountRequest: MutationFunction<CreditApplication, BankAccountRequestDto> = async (
  bankAccountRequestDto: BankAccountRequestDto,
) => {
  const apiClient = getApiClient()
  const response = await apiClient.post(
    `/CreditApplication/${EFinancingProgram.Personal}/${bankAccountRequestDto.creditApplicationId}/SkipBankAccountRequest`,
    bankAccountRequestDto,
  )

  return response.data as CreditApplication
}

export function useSkipBankAccountRequest(): [
  MutationFunction<CreditApplication, BankAccountRequestDto>,
  boolean,
  boolean,
  boolean,
  () => void,
] {
  const queryClient = useQueryClient()

  const { mutateAsync, isPending, isError, isSuccess, reset } = useMutation({
    mutationFn: skipBankAccountRequest,
    onSuccess: (updatedData) => {
      queryClient.setQueryData(keysFactory.detail(updatedData.id), updatedData)
    },
  })

  return [mutateAsync, isPending, isError, isSuccess, reset]
}

const createBankAccountRequestAndSendUrl: MutationFunction<CreditApplication, BankAccountRequestDto> = async (
  bankAccountRequestDto: BankAccountRequestDto,
) => {
  const apiClient = getApiClient()
  const response = await apiClient.post<CreditApplication>(
    `/CreditApplication/${EFinancingProgram.Personal}/${bankAccountRequestDto.creditApplicationId}/CreateBankAccountRequest`,
    bankAccountRequestDto,
  )

  return response.data
}

export function useCreateBankAccountRequestAndSendUrl(): [
  MutationFunction<CreditApplication, BankAccountRequestDto>,
  boolean,
  boolean,
  boolean,
  () => void,
] {
  const queryClient = useQueryClient()

  const { mutateAsync, isPending, isError, isSuccess, reset } = useMutation({
    mutationFn: createBankAccountRequestAndSendUrl,
    onSuccess: (updatedData) => {
      queryClient.setQueryData(keysFactory.detail(updatedData.id), updatedData)
    },
  })

  return [mutateAsync, isPending, isError, isSuccess, reset]
}

export type FundingSummaryDto = {
  financedAmount: string
  activationOn: string
  merchantId: string
  merchantPaymentMethod: string
  merchantName: string
  merchantAddress: string
  merchantPhone: string
  promotion: string
  merchantFee: string
  customerAccountNumber: string
  customerNameProvince: string
  customerService: EServiceCategory
}

const getFundingSummaryById: QueryFunction<
  FundingSummaryDto,
  ReturnType<(typeof keysFactory)['summary']>,
  [string]
> = async ({ queryKey: [{ id }], meta }) => {
  const apiClient = getApiClient()
  const response = await apiClient.get<FundingSummaryDto>(
    `/CreditApplication/${encodeURIComponent(meta!.financingProgramId as EFinancingProgram)}/${encodeURIComponent(
      id,
    )}/funding-summary`,
  )
  return response.data
}

export function useFundingSummaryById(
  creditApplicationId: string,
  financingProgramId: EFinancingProgram,
): [FundingSummaryDto, boolean] {
  const { data, isFetching } = useQuery({
    queryKey: keysFactory.summary(creditApplicationId),
    queryFn: getFundingSummaryById,
    enabled: !!creditApplicationId,
    staleTime: fiveMinutes,
    gcTime: fiveMinutes,
    meta: { financingProgramId },
  })

  return [data!, isFetching]
}

const continueWithComputedIncome: MutationFunction<
  CreditApplication,
  { creditApplicationId: string; applicantType: EApplicantType }
> = async ({ creditApplicationId, applicantType }) => {
  const apiClient = getApiClient()
  const response = await apiClient.post<CreditApplication>(
    `/CreditApplication/${EFinancingProgram.Personal}/${creditApplicationId}/ContinueWithComputedIncome/${applicantType}`,
  )

  return response.data
}

export function useContinueWithComputedIncome(): [
  typeof continueWithComputedIncome,
  boolean,
  boolean,
  boolean,
  () => void,
] {
  const queryClient = useQueryClient()
  const { mutateAsync, isPending, isError, isSuccess, reset } = useMutation({
    mutationFn: continueWithComputedIncome,
    onSuccess: (updatedData) => {
      queryClient.setQueryData(keysFactory.detail(updatedData.id), updatedData)
    },
  })

  return [mutateAsync, isPending, isError, isSuccess, reset]
}

const resetBankAccountRequest: MutationFunction<CreditApplication, BankAccountRequestDto> = async (
  bankAccountRequestDto: BankAccountRequestDto,
) => {
  const apiClient = getApiClient()
  const response = await apiClient.post<CreditApplication>(
    `/CreditApplication/${EFinancingProgram.Personal}/${bankAccountRequestDto.creditApplicationId}/ResetBankAccount`,
    bankAccountRequestDto,
  )

  return response.data
}

export function useResetBankAccountRequest(): [typeof resetBankAccountRequest, boolean, boolean, boolean, () => void] {
  const queryClient = useQueryClient()
  const { mutateAsync, isPending, isError, isSuccess, reset } = useMutation({
    mutationFn: resetBankAccountRequest,
    onSuccess: (updatedData) => {
      queryClient.setQueryData(keysFactory.detail(updatedData.id), updatedData)
    },
  })

  return [mutateAsync, isPending, isError, isSuccess, reset]
}

const skipBankAccountRequestRequirements = async ({
  queryKey: [{ id }],
}: QueryFunctionContext<ReturnType<(typeof keysFactory)['skipBankAccountRequestRequirements']>>) => {
  const apiClient = getApiClient()
  const response = await apiClient.get<RequiredDocument[]>(
    `/CreditApplication/${EFinancingProgram.Personal}/${encodeURIComponent(id)}/SkipBankRequestRequirements`,
  )
  return response.data
}

export function useSkipBankAccountRequestRequirements(creditApplicationId: string): [RequiredDocument[], boolean] {
  const { data, isFetching } = useQuery({
    queryKey: keysFactory.skipBankAccountRequestRequirements(creditApplicationId),
    queryFn: skipBankAccountRequestRequirements,
    enabled: !!creditApplicationId,
    staleTime: fiveMinutes,
    gcTime: fiveMinutes,
  })

  return [data!, isFetching]
}
