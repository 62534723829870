import { CreditApplication } from '@src/types/CreditApplicationSchema'
import { EOriginSystemId } from '@src/types/Constants'
import ContractInformation from './ContractInformation'
import DownloadContractButton from './DownloadContractButton'

type Props = {
  creditApp: CreditApplication
}
const ContractCompleted = ({ creditApp }: Props) => {
  return (
    <>
      <ContractInformation creditApp={creditApp} />
      {creditApp.originSystemId === EOriginSystemId.MerchantDashboard && (
        <DownloadContractButton creditApp={creditApp} />
      )}
    </>
  )
}

export default ContractCompleted
