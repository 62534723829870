import { Typography } from '@mui/material'
import { CreditApplication } from '@src/types/CreditApplicationSchema'
import { useTranslation } from 'react-i18next'
import CancelCreditAppButton from '../CancelCreditAppButton'

type Props = {
  creditApp: CreditApplication
  canEdit: boolean
}
const CreditManualRevision = ({ creditApp, canEdit }: Props) => {
  const { t } = useTranslation()
  return (
    <>
      <Typography variant="body1">{t('viewCreditApplication.clientManualRevision')}</Typography>
      {canEdit && <CancelCreditAppButton creditApp={creditApp} />}
    </>
  )
}

export default CreditManualRevision
