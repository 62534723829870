import { Navigate, Route, Routes } from 'react-router-dom'
import AuthPage from '../Authentication'
import LoginPage from '../Authentication/LoginPage'
import BrowseCreditApplicationPage from '../BrowseCreditApplicationPage'
import CreateCreditApplicationPage from '../CreditApplicationPage/CreateCreditApplicationPage'
import ViewCreditApplicationPage from '../ViewCreditApplicationPage'
import AddCoapplicantPage from '../AddCoapplicantPage/components/AddCoapplicantPage'
import EditCreditApplicationPage from '../CreditApplicationPage/EditCreditApplicationPage'
import FundingSummary from '../FundingSummary'
import CreateWorksheetPage from '../CreateWorksheetPage/CreateWorksheetPage'

const TopLevelRoutes = (): JSX.Element => {
  return (
    <Routes>
      <Route path="/" element={<Navigate to="/credit/browse" replace />} />
      <Route path="/credit/browse" element={<BrowseCreditApplicationPage />} />
      <Route path="/credit/add" element={<CreateCreditApplicationPage />} />
      <Route path="/credit/:id/coapplicant" element={<AddCoapplicantPage />} />
      <Route path="/credit/:id/edit" element={<EditCreditApplicationPage />} />
      <Route path="/credit/:id/Worksheet" element={<CreateWorksheetPage />} />
      <Route path="/credit/:financingProgramId/:id/funding-summary" element={<FundingSummary />} />
      <Route path="/login" element={<LoginPage />} />
      <Route path="/auth-page" element={<AuthPage />} />
      <Route path="/credit/:id/view" element={<ViewCreditApplicationPage />} />
    </Routes>
  )
}

export default TopLevelRoutes
