import { TextField, StandardTextFieldProps } from '@mui/material'
import React from 'react'
import { FieldError } from 'react-hook-form'
import translateErrorCode from './TranslateError'

interface Props extends Omit<StandardTextFieldProps, 'error'> {
  error?: FieldError
}

const InputTextField = React.forwardRef<HTMLInputElement, Props>(function InputTextField(
  { error, ...othersProps },
  ref,
) {
  return (
    <TextField
      {...othersProps}
      inputRef={ref}
      error={error !== undefined}
      helperText={error ? translateErrorCode(error) : ' '}
      variant="outlined"
      fullWidth
      InputLabelProps={{ shrink: true }}
      autoComplete="off"
    />
  )
})

export default InputTextField
