import { AppBar, Toolbar, useTheme, Typography, Container } from '@mui/material'

type AlertBannerProps = {
  message: string
  variant?: 'error' | 'info' | 'warning' | 'success'
  show?: boolean
}

const AlertBanner = ({ message, variant = 'error', show = true }: AlertBannerProps) => {
  const theme = useTheme()

  const barColor = variant === 'error' ? theme.palette.error.main : theme.palette.info.main

  return (
    <AppBar
      position="relative"
      sx={{ backgroundColor: barColor, top: 0, display: show ? 'flex' : 'none', zIndex: 1500 }}
    >
      <Container maxWidth="xl">
        <Toolbar variant="regular" style={{ justifyContent: 'center', display: 'flex' }}>
          <Typography variant="h5" color="inherit">
            {message}
          </Typography>
        </Toolbar>
      </Container>
    </AppBar>
  )
}

export default AlertBanner
