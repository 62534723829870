import { MutationFunction, QueryFunctionContext, useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { getApiClient } from '../api-client'
import { User } from '../../../types/User'

export type JwtToken = {
  jwt: string
}

const SCOPE = 'user-profile'
const DETAIL = 'detail'

const keysFactory = {
  detail: () => [{ scope: SCOPE, entity: DETAIL }] as const,
}

const getUserProfile = async (_ctx: QueryFunctionContext<ReturnType<(typeof keysFactory)['detail']>>) => {
  const apiClient = getApiClient()
  const response = await apiClient.get('/MyProfile')

  return response.data as User
}

export function useGetUserProfile(userId: string | undefined): [User | null, boolean] {
  const { isFetching, data } = useQuery({
    queryKey: keysFactory.detail(),
    queryFn: getUserProfile,
    enabled: !!userId,
  })

  return [data ?? null, isFetching]
}

const updateUserProfile: MutationFunction<User, User> = async (dto: User) => {
  const apiClient = getApiClient()
  const response = await apiClient.put('/MyProfile', dto)
  return response.data as User
}

export function useUpdateUserProfile(): [MutationFunction<User, User>, boolean, boolean, () => void] {
  const queryClient = useQueryClient()
  const { isPending, isError, mutateAsync, reset } = useMutation({
    mutationFn: updateUserProfile,
    onSuccess: (data) => {
      queryClient.setQueryData(keysFactory.detail(), data)
      return data
    },
  })

  return [mutateAsync, isPending, isError, reset]
}
