import React from 'react'
import { Dialog, DialogActions, DialogContent, DialogTitle, IconButton } from '@mui/material'
import { Cancel as CancelIcon, Done as DoneIcon, Close as CloseIcon } from '@mui/icons-material'
import { useTranslation } from 'react-i18next'
import AsyncActionButton from './AsyncActionButton'

type Props = {
  onCancel: () => void
  onConfirm: () => void
  title: string | React.ReactNode
  children?: React.ReactNode
  open: boolean
  disabled?: boolean
}

const ActionsDialog = ({ onCancel, onConfirm, title, children, open, disabled = false }: Props): JSX.Element => {
  const { t } = useTranslation()
  return (
    <Dialog
      onClose={onCancel}
      open={open}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth="xl"
      keepMounted
      transitionDuration={0}
      disablePortal
      BackdropProps={{ transitionDuration: 0 }}
    >
      <DialogTitle style={{ borderBottom: '1px solid', textAlign: 'center' }} id="alert-dialog-title">
        {title}
        <IconButton
          aria-label="close"
          onClick={onCancel}
          sx={{
            position: 'absolute',
            right: 15,
            top: 15,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>{children}</DialogContent>
      <DialogActions>
        <AsyncActionButton fullWidth={false} startIcon={<CancelIcon />} onClick={onCancel}>
          {t('common.cancel')}
        </AsyncActionButton>
        <AsyncActionButton
          fullWidth={false}
          disabled={disabled}
          onClick={onConfirm}
          variant="contained"
          startIcon={<DoneIcon />}
        >
          {t('common.confirm')}
        </AsyncActionButton>
      </DialogActions>
    </Dialog>
  )
}

export default React.memo(ActionsDialog)
