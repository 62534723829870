import {
  Chip,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material'
import { RequiredDocument } from '@src/types/RequiredDocument'
import { Dispatch } from 'react'
import { useTranslation } from 'react-i18next'
import DocumentTableRow from './DocumentTableRow'

type Props = {
  requiredDocuments: RequiredDocument[]
  creditApplicationId: string
  setError: Dispatch<Error>
  canEdit: boolean
}

const DocumentTable = ({ requiredDocuments, creditApplicationId, setError, canEdit }: Props) => {
  const { t } = useTranslation()
  const docs = requiredDocuments
  return (
    <>
      <Grid item xs={12} m={1}>
        <TableContainer
          elevation={2}
          component={Paper}
          sx={{ borderRadius: 2, scrollbarWidth: 'thin', marginTop: '3rem' }}
        >
          <Table>
            <TableHead>
              <TableRow>
                <TableCell sx={{ borderBottom: 'none' }}>
                  <Typography variant="h6" gutterBottom component="div">
                    Documents
                  </Typography>
                </TableCell>
              </TableRow>
            </TableHead>
          </Table>
          <Table>
            <TableHead>
              <TableRow
                style={{ color: 'white' }}
                sx={{
                  backgroundColor: '#14233A',
                  color: 'white',
                }}
              >
                <TableCell style={{ color: 'white' }}>{t('documentSectionHeaders.document')}</TableCell>
                <TableCell style={{ color: 'white' }} sx={{ textAlign: 'center' }}>
                  {t('documentSectionHeaders.required')}
                </TableCell>
                <TableCell style={{ color: 'white' }} sx={{ textAlign: 'center' }}>
                  {t('documentSectionHeaders.requestedOn')}
                </TableCell>
                <TableCell style={{ color: 'white' }} sx={{ textAlign: 'center' }}>
                  {t('documentSectionHeaders.receivedOn')}
                </TableCell>
                <TableCell style={{ color: 'white' }} sx={{ textAlign: 'center' }}>
                  {t('documentSectionHeaders.status')}
                </TableCell>
                <TableCell style={{ color: 'white' }}>{t('documentSectionHeaders.notes')}</TableCell>
                {canEdit && (
                  <TableCell style={{ color: 'white' }} sx={{ textAlign: 'center' }}>
                    {t('documentSectionHeaders.action')}
                  </TableCell>
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              {docs?.map((item: RequiredDocument) => (
                <DocumentTableRow
                  documentRequired={item}
                  key={item.typeId}
                  creditApplicationId={creditApplicationId}
                  setError={setError}
                  canEdit={canEdit}
                />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
      {!docs.length && (
        <div style={{ padding: '1rem 0 0 1rem', textAlign: 'center', width: '100%' }}>
          <Chip label={t('common.notRequiredDocuments')} size="medium" />
        </div>
      )}
    </>
  )
}

export default DocumentTable
