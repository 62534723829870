import { EApplicantType, EDocType, EDocumentStatus } from '@src/types/Constants'
import { ApplicantDto } from '@src/types/CreditApplicationSchema'
import { RequiredDocument } from '@src/types/RequiredDocument'
import { useMemo } from 'react'

export const useHasRequiredDocuments = (
  requiredDocuments: RequiredDocument[],
  documentTypes: (number | string)[],
  applicantType: EApplicantType,
  status: EDocumentStatus,
): boolean => {
  const hasRequiredIncomeDocuments: boolean = useMemo(
    () =>
      requiredDocuments.some(
        (d) => documentTypes.includes(d.typeId) && d.status === status && d.applicantType === applicantType,
      ),
    [applicantType, documentTypes, requiredDocuments, status],
  )

  return hasRequiredIncomeDocuments
}

export const useHasBankStatementOrVoidCheque = (
  requiredDocuments: RequiredDocument[],
  applicantType: EApplicantType,
): boolean => {
  const hasBankStatementOrVoidCheque: boolean = useMemo(
    () =>
      requiredDocuments.some(
        (d) =>
          (d.typeId === EDocType.VoidCheque || d.typeId === EDocType.SixMonthsPersonalBankStatements) &&
          d.applicantType === applicantType,
      ),
    [applicantType, requiredDocuments],
  )

  return hasBankStatementOrVoidCheque
}

export const getVerifiedIncome = (
  hasBankStatementOrVoidCheque: boolean,
  hasIncomeDocumentsApproved: boolean,
  applicant: ApplicantDto,
): number => {
  if (hasBankStatementOrVoidCheque && applicant.incomeVerification?.incomeMismatch && !hasIncomeDocumentsApproved) {
    return applicant.incomeVerification.thirdPartyIncome
  }
  return applicant.incomeVerification!.declaredIncome
}

export const useCanContinueWithComputedIncome = (applicant: ApplicantDto) => {
  return useMemo<boolean>(
    () =>
      applicant.incomeVerification!.thirdPartyIncome >= 21000 &&
      applicant.incomeVerification!.thirdPartyIncome / applicant.incomeVerification!.declaredIncome >= 0.5 &&
      !applicant.incomeVerification!.nameMismatch,
    [applicant],
  )
}
