import { Box, Divider, Typography } from '@mui/material'
import { ApplicantDto } from '@src/types/CreditApplicationSchema'
import {
  Control,
  FieldErrors,
  UseFormGetValues,
  UseFormRegister,
  UseFormSetValue,
  UseFormTrigger,
  UseFormWatch,
} from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import AddressComponent from './components/AddressComponent'
import ApplicantExpensesSection from './components/ApplicantExpenseComponent'
import ApplicantIncomeSection from './components/ApplicantIncomeComponent'
import ApplicantInformationParameters from './components/ApplicantInformationComponent'

type Props = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  register: UseFormRegister<any>
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  watch: UseFormWatch<any>
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setValue: UseFormSetValue<any>
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  getValues: UseFormGetValues<any>
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  trigger: UseFormTrigger<any>
  errors: FieldErrors<ApplicantDto> | undefined
  name?: 'coApplicant' | 'applicant'
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  formControl: Control<any>
  editDisabled: boolean
  prohibitedPhone?: string | null
}

const ApplicantParameters = ({
  register,
  watch,
  setValue,
  getValues,
  trigger,
  errors,
  name,
  formControl,
  editDisabled,
  prohibitedPhone,
}: Props) => {
  const { t } = useTranslation()
  const typedErrors = errors as FieldErrors<ApplicantDto>
  const prefix: 'coApplicant.' | 'applicant.' | '' = name ? (name.concat('.') as 'coApplicant.' | 'applicant.') : ''

  return (
    <>
      <ApplicantInformationParameters
        register={register}
        errors={typedErrors}
        name={prefix}
        formControl={formControl}
        setValue={setValue}
        getValues={getValues}
        prohibitedPhone={prohibitedPhone}
      />
      <Box
        sx={{
          height: 20,
        }}
      />
      <Divider>
        <Typography variant="h6" gutterBottom>
          {t('editCreditApplication.address.label')}
        </Typography>
      </Divider>
      <AddressComponent
        formControl={formControl}
        register={register}
        errors={typedErrors?.currentAddress}
        name={`${prefix}currentAddress`}
      />
      <Divider>
        <Typography variant="h6" gutterBottom>
          {t('editCreditApplication.expenses.label')}
        </Typography>
      </Divider>
      <ApplicantExpensesSection
        register={register}
        watch={watch}
        setValue={setValue}
        trigger={trigger}
        errors={typedErrors?.expenses}
        name={`${prefix}expenses`}
        showDetailedForm={false}
      />
      <ApplicantIncomeSection
        register={register}
        errors={typedErrors?.otherIncomes}
        formControl={formControl}
        name={prefix}
        disableEdit={editDisabled}
      />
    </>
  )
}
export default ApplicantParameters
