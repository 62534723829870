import { ECreditApplicationStatus, ECurrentCustomerStep } from '@src/types/Constants'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

export function useCreditApplicationStatuses() {
  const { t } = useTranslation()

  return useMemo(
    () => [
      { label: t('common.all'), value: '' },
      { label: t('creditApplicationStatus.active'), value: ECreditApplicationStatus.Active },
      { label: t('creditApplicationStatus.cancelled'), value: ECreditApplicationStatus.Cancelled },
      { label: t('creditApplicationStatus.completed'), value: ECreditApplicationStatus.Completed },
      { label: t('creditApplicationStatus.draft'), value: ECreditApplicationStatus.Draft },
      { label: t('creditApplicationStatus.expired'), value: ECreditApplicationStatus.Expired },
    ],
    [t],
  )
}

export function useCurrentCustomerSteps() {
  const { t } = useTranslation()

  return useMemo(
    () => [
      { label: t('common.all'), value: '' },
      { label: t('dashboardEntriesStatus.prequal'), value: ECurrentCustomerStep.Prequal },
      { label: t('dashboardEntriesStatus.credit'), value: ECurrentCustomerStep.Credit },
      { label: t('dashboardEntriesStatus.incomeVerification'), value: ECurrentCustomerStep.IncomeVerification },
      { label: t('dashboardEntriesStatus.contract'), value: ECurrentCustomerStep.Contract },
      { label: t('dashboardEntriesStatus.funding'), value: ECurrentCustomerStep.Funding },
    ],
    [t],
  )
}
