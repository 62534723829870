import { useNavigate } from 'react-router-dom'
import { useEffect } from 'react'

// Page called back by Azure to submit
// the magic happens when we initialize msal in main.tsx
// MSAL will push the token in the URL to the MerchandApi and get a valid JwtToken
// we simply redirect to / so that the router can render the correct page
const AuthPage = (): JSX.Element => {
  const navigate = useNavigate()
  useEffect(() => {
    navigate('/')
  }, [navigate])

  return <div />
}

export default AuthPage
