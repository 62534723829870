import { Box, Card, CardContent, CardMedia, Stack } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useMsal } from '@azure/msal-react'
import AsyncActionButton from '@src/components/AsyncActionButton'
import LanguagePicker from '../App/components/LanguagePicker'
import { loginRequest } from '../../authConfig'

const LoginPage = () => {
  const { t } = useTranslation()
  document.title = t('common.login')

  const { instance } = useMsal()

  const handleLogin = async () => {
    await instance.loginRedirect(loginRequest)
  }

  return (
    <Box display="flex" justifyContent="center" alignItems="center" minHeight="100vh">
      <Card>
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <LanguagePicker />
        </div>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <CardMedia sx={{ width: '200px' }} component="img" image="/images/logo.svg" alt="iFinance" />
        </div>
        <CardContent>
          <Stack spacing={3} sx={{ paddingLeft: '100px', paddingRight: '100px', paddingTop: '20px' }}>
            <AsyncActionButton fullWidth={false} id="login" variant="contained" type="button" onClick={handleLogin}>
              {t('common.login')}
            </AsyncActionButton>
          </Stack>
        </CardContent>
      </Card>
    </Box>
  )
}

export default LoginPage
