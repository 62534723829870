import { EDocType } from '@src/types/Constants'

export const getIncomeVerificationDocumentsTypes = [
  EDocType.DisabilityIncomeProof,
  EDocType.LastPayStubJob,
  EDocType.SixMonthsPersonalBankStatements,
  EDocType.VoidCheque,
  EDocType.FamilyAllocationsProof,
  EDocType.WorkIncidentIndemnisationProof,
  EDocType.MaternityPaternityIncomeProof,
  EDocType.OtherIncomeProof,
  EDocType.RetirementIncomeProof,
  EDocType.AssistanceProof,
  EDocType.EmployementInsuranceProof,
]

export const getIdentityDocumentTypes = [EDocType.ValidPhotoId]
