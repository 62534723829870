import { Button } from '@mui/material'
import { reportErrorToConsole } from '@src/services/error-logger'
import { useTranslation } from 'react-i18next'

const LanguagePicker = (): JSX.Element => {
  const { t, i18n } = useTranslation()
  const targetLanguage = t('common.targetLanguage')

  const changeLanguage = () => {
    i18n.changeLanguage(targetLanguage).catch(reportErrorToConsole)
  }

  return <Button onClick={changeLanguage}>{targetLanguage}</Button>
}

export default LanguagePicker
