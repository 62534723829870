import { Box, Typography } from '@mui/material'
import { FundingSummaryDto } from '@src/data/api/credit-api/credit-api'
import { useTranslation } from 'react-i18next'

interface Props {
  fundingSummary: FundingSummaryDto
}

const ClientInformation = ({ fundingSummary }: Props) => {
  const { t } = useTranslation()

  return (
    <Box sx={{ marginLeft: '15%' }}>
      <Typography variant="h4" gutterBottom sx={{ color: '#08ABF0', marginTop: '5%' }}>
        {t('fundingSummary.clientInformation')}
      </Typography>

      <Box sx={{ marginLeft: '2.5%' }}>
        <Typography variant="subtitle1" gutterBottom>
          <strong>{t('fundingSummary.accountNumber')}</strong> {fundingSummary?.customerAccountNumber}
        </Typography>
        <Typography variant="subtitle1" gutterBottom>
          <strong>{t('fundingSummary.nameProvince')}</strong> {fundingSummary?.customerNameProvince}
        </Typography>
        <Typography variant="subtitle1" gutterBottom>
          <strong>{t('fundingSummary.service')}</strong> {t(`serviceCategory.${fundingSummary.customerService}`)}
        </Typography>
        <Typography variant="subtitle1" gutterBottom>
          <strong>{t('fundingSummary.fundingDate')}</strong> {fundingSummary?.activationOn}
        </Typography>
      </Box>
    </Box>
  )
}

export default ClientInformation
