import * as React from 'react'
import { Button, Menu, MenuItem } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useAccount, useMsal } from '@azure/msal-react'
import { AccountCircle as AccountCircleIcon, MonetizationOn as MonetizationOnIcon } from '@mui/icons-material'
import { Link } from 'react-router-dom'
import EditProfileDialog from './EditProfileDialog'

const AppMenu = (): JSX.Element => {
  const { t } = useTranslation()
  const { instance } = useMsal()
  const account = useAccount()
  const menuStyle = { textDecoration: 'none' }
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const [openProfile, setOpenProfile] = React.useState(false)

  const handleOpenProfile = () => {
    setAnchorEl(null)
    setOpenProfile(true)
  }

  const handleCloseProfile = () => {
    setOpenProfile(false)
  }

  const open = Boolean(anchorEl)

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <div style={{ display: 'flex' }}>
      <Button
        id="btnUser"
        aria-controls={open ? 'user-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        sx={{
          display: 'block',
          color: 'white',
          '& svg': {
            fill: 'white',
            verticalAlign: 'middle',
          },
          textAlign: 'left',
          lineHeight: 'inherit',
        }}
        onClick={handleClick}
      >
        <AccountCircleIcon sx={{ marginRight: 1 }} />
        {t('menus.account')}
      </Button>
      <Button
        id="btnUser"
        aria-controls={open ? 'user-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        sx={{
          color: 'white',
          '& svg': {
            fill: 'white',
          },
        }}
        component={Link}
        to="/"
      >
        <MonetizationOnIcon sx={{ marginRight: 1 }} />
        {t('menus.application')}
      </Button>

      <Menu id="user-menu" anchorEl={anchorEl} open={open} onClose={handleClose}>
        <MenuItem style={menuStyle} onClick={handleOpenProfile}>
          {t('menus.profile')}
        </MenuItem>
        <MenuItem
          style={menuStyle}
          onClick={async () => {
            localStorage.clear()
            if (account) await instance.logoutRedirect({ account })
            else window.location.reload()
          }}
        >
          {t('menus.logout')}
        </MenuItem>
      </Menu>
      {openProfile && <EditProfileDialog open={openProfile} handleClose={handleCloseProfile} />}
    </div>
  )
}

export default AppMenu
