import { Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { EFinancingProgram } from '@src/types/Constants'
import FundingSummaryButton from './FundingSummaryButton'

type Props = {
  activationDate: string
  id: string
  financingProgramId: EFinancingProgram
}
const FundingLoanCreated = ({ activationDate, id, financingProgramId }: Props) => {
  const { t } = useTranslation()
  return (
    <div>
      <Typography variant="subtitle1">{t('viewCreditApplication.activationDate')}</Typography>
      <Typography variant="body1">{activationDate}</Typography>
      <br />
      <Typography variant="body1">{t('viewCreditApplication.fundingAwaitingActivationDate')}</Typography>
      <br />
      <br />
      <FundingSummaryButton id={id} financingProgramId={financingProgramId} />
    </div>
  )
}

export default FundingLoanCreated
