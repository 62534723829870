import {
  CvtAwaitingApproval,
  anyDocsAwaitingApproval,
  anyDocsAwaitingUploadOrRefused,
  availableCreditEqualApprovedAmount,
} from '@src/data/creditapp-selectors'
import {
  EContractStatus,
  ECreditApplicationStatus,
  EDecision,
  EOriginSystemId,
  EWorksheetStatus,
  IconType,
} from '@src/types/Constants'
import { FilteredWorksheet } from '@src/types/FilteredWorksheet'
import { ApplicantDto, CreditApplication } from '@src/types/CreditApplicationSchema'
import { canPollCreditApp } from './credit-hooks'

export const GetDefaultIconAndMessageFromDecision = (
  decision: EDecision,
  creditApp: CreditApplication,
  isAnalysing: boolean,
  docsAwaitingApproval: boolean,
) => {
  let iconMessage
  let iconType: IconType | null = null

  const docsAwaitingUploadOrRefused = anyDocsAwaitingUploadOrRefused(creditApp.requiredDocuments)

  if (docsAwaitingUploadOrRefused && decision === EDecision.ManualRevision) {
    iconMessage = ECreditApplicationStatus.DocumentsRequired
    iconType = IconType.warning
    return { iconMessage, iconType }
  }

  if (docsAwaitingApproval && decision !== EDecision.Accept) {
    iconMessage = ECreditApplicationStatus.AwaitingApproval
    iconType = IconType.info
    return { iconMessage, iconType }
  }

  if (creditApp.status === ECreditApplicationStatus.Cancelled) {
    iconMessage = ECreditApplicationStatus.Cancelled
    iconType = IconType.error
    return { iconMessage, iconType }
  }
  if ((!decision && !isAnalysing) || (creditApp.consentHardHit && !isAnalysing && decision === EDecision.Pending)) {
    iconMessage = ECreditApplicationStatus.Error
    iconType = IconType.warning
    return { iconMessage, iconType }
  }
  if (
    !creditApp.consentHardHit &&
    creditApp.status === ECreditApplicationStatus.Active &&
    (decision === EDecision.Error || decision === EDecision.ManualRevision) &&
    creditApp.originSystemId !== EOriginSystemId.MerchantDashboard
  ) {
    iconMessage = ECreditApplicationStatus.ManualRevision
    iconType = IconType.info
    return { iconMessage, iconType }
  }

  switch (decision) {
    case EDecision.ManualRevision:
      iconMessage = ECreditApplicationStatus.ManualRevision
      iconType = IconType.waiting
      break
    case EDecision.Accept:
      iconMessage = ECreditApplicationStatus.Completed
      iconType = IconType.success
      break
    case EDecision.Refused:
      iconMessage = ECreditApplicationStatus.Declined
      iconType = IconType.error
      break
    case EDecision.Error:
      iconType = IconType.warning
      iconMessage = ECreditApplicationStatus.Error
      break
    default:
      iconType = null
      iconMessage = null
      break
  }
  return { iconMessage, iconType }
}

export const GetIconAndMessageForIncomeVerification = (
  applicant: ApplicantDto,
  canStartStep: boolean,
  hasIncomeDocumentsPendingReview: boolean,
  hasIncomeDocumentsPendingUpload: boolean,
  hasIncomeDocumentsApproved: boolean,
  isDone: boolean,
  applicationStatus: ECreditApplicationStatus,
  hasIdentityDocumentPendingReview: boolean,
  hasIdentityDocumentApproved: boolean,
) => {
  let iconMessage: ECreditApplicationStatus | null = null
  let iconType: IconType | null = null

  if (!canStartStep) {
    return { iconMessage, iconType }
  }

  if (applicationStatus === ECreditApplicationStatus.Cancelled) {
    iconMessage = ECreditApplicationStatus.Cancelled
    iconType = IconType.error
    return { iconMessage, iconType }
  }

  const mismatch = applicant.incomeVerification?.incomeMismatch || applicant.incomeVerification?.nameMismatch
  const isWaitingForClient = !!applicant.incomeVerification?.requiredExternalStep && !isDone && !mismatch
  const nameMismatchUnderReview =
    applicant.incomeVerification?.nameMismatch && (hasIdentityDocumentPendingReview || hasIdentityDocumentApproved)
  const hasDocumentsPendingUpload = hasIncomeDocumentsPendingUpload && !isDone && !nameMismatchUnderReview
  const isUnderReview =
    nameMismatchUnderReview ||
    (!hasIncomeDocumentsPendingUpload && hasIncomeDocumentsPendingReview) ||
    (hasIncomeDocumentsApproved && !isDone)
  const hasNotStartedThirdPartyVerification = !applicant.incomeVerification?.requiredExternalStep

  switch (true) {
    case isWaitingForClient:
      iconMessage = ECreditApplicationStatus.Progress
      iconType = IconType.info
      break
    case hasDocumentsPendingUpload:
      iconMessage = ECreditApplicationStatus.DocumentsRequired
      iconType = IconType.warning
      break
    case isDone:
      iconMessage = ECreditApplicationStatus.Completed
      iconType = IconType.success
      break
    case isUnderReview:
      iconMessage = ECreditApplicationStatus.ManualRevision
      iconType = IconType.waiting
      break
    case hasNotStartedThirdPartyVerification:
      iconMessage = ECreditApplicationStatus.Waiting
      iconType = IconType.warning
      break
    default:
  }

  return { iconMessage, iconType }
}

export const GetFundingIconAndMessage = (creditApp: CreditApplication) => {
  let iconMessage: ECreditApplicationStatus
  let iconType: IconType | null = null
  const today = new Date(`T00:00:00.000Z`)

  if (anyDocsAwaitingUploadOrRefused(creditApp.requiredDocuments)) {
    iconMessage = ECreditApplicationStatus.DocumentsRequired
    iconType = IconType.warning
    return { iconMessage, iconType }
  }
  if (anyDocsAwaitingApproval(creditApp.requiredDocuments)) {
    iconMessage = ECreditApplicationStatus.ManualRevision
    iconType = IconType.waiting
    return { iconMessage, iconType }
  }
  if (!creditApp.loanCreated || new Date(creditApp.deliveryOn) <= today) {
    iconMessage = ECreditApplicationStatus.Processing
    iconType = IconType.waiting
    return { iconMessage, iconType }
  }
  iconMessage = ECreditApplicationStatus.Completed
  iconType = IconType.success
  return { iconMessage, iconType }
}

export const GetContractIconAndMessage = (creditApp: CreditApplication, worksheet: FilteredWorksheet) => {
  let iconMessage: ECreditApplicationStatus | null = null
  let iconType: IconType | null = null
  const isCvtAwaitingApproval = CvtAwaitingApproval(creditApp.requiredDocuments)
  const isAvailableCreditEqualApprovaleAmount = availableCreditEqualApprovedAmount(creditApp)

  if (creditApp.status === ECreditApplicationStatus.Cancelled) {
    iconMessage = ECreditApplicationStatus.Cancelled
    iconType = IconType.error
    return { iconMessage, iconType }
  }
  if (
    (worksheet == null && !creditApp.hasTasksBeforeCvt) ||
    (worksheet?.status === EWorksheetStatus.Draft &&
      worksheet?.finalDecision.decision === EDecision.Pending &&
      !creditApp.hasTasksBeforeCvt) ||
    (worksheet?.status === EWorksheetStatus.Draft && isAvailableCreditEqualApprovaleAmount)
  ) {
    if (creditApp.originSystemId !== EOriginSystemId.MerchantDashboard) {
      iconMessage = ECreditApplicationStatus.ManualRevision
      iconType = IconType.waiting
    } else {
      iconMessage = ECreditApplicationStatus.ActionRequired
      iconType = IconType.warning
    }
    return { iconMessage, iconType }
  }
  if (
    worksheet?.finalDecision.decision === EDecision.ManualRevision ||
    isCvtAwaitingApproval ||
    creditApp.hasTasksBeforeCvt
  ) {
    iconMessage = ECreditApplicationStatus.AwaitingApproval
    iconType = IconType.waiting
    return { iconMessage, iconType }
  }
  if (
    worksheet?.finalDecision.decision === EDecision.Accept &&
    (creditApp.contract?.status === EContractStatus.ready || creditApp.contract?.status === EContractStatus.sending)
  ) {
    iconMessage = ECreditApplicationStatus.AwaitingSignature
    iconType = IconType.warning
    return { iconMessage, iconType }
  }
  if (worksheet?.status === EWorksheetStatus.Active && creditApp.contract?.status === EContractStatus.completed) {
    iconMessage = ECreditApplicationStatus.Completed
    iconType = IconType.success
    return { iconMessage, iconType }
  }
  if (creditApp.contract.status === EContractStatus.notReady && !canPollCreditApp(creditApp)) {
    iconMessage = ECreditApplicationStatus.Error
    iconType = IconType.warning
    return { iconMessage, iconType }
  }
  return { iconMessage, iconType }
}
