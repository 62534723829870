import { useEffect } from 'react'
import { Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, TextField } from '@mui/material'
import { useTranslation } from 'react-i18next'
import CloseIcon from '@mui/icons-material/Close'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { useMsal } from '@azure/msal-react'
import { RedirectRequest } from '@azure/msal-browser'
import AsyncActionButton from '@src/components/AsyncActionButton'
import { useGetUserProfile, useUpdateUserProfile } from '@src/data/api/user-api/user-api'
import { User } from '@src/types/User'
import { EditProfileInformation, EditProfileInformationSchema } from '@src/types/profileSchema'
import { useUser } from '@src/contexts/AppContext'
import PageSpinner from '@src/components/PageSpinner'
import InputTextField from '@src/components/InputTextField'
import InputTextFieldWithMask from '@src/components/InputMask'
import { EMask } from '@src/types/Constants'

type Props = {
  open: boolean
  handleClose: () => void
}

const EditProfileDialog = ({ open, handleClose }: Props) => {
  const { t } = useTranslation()
  const { instance } = useMsal()
  const userToken = useUser()
  const [user, isLoadingUser] = useGetUserProfile(userToken?.id)

  const {
    register,
    handleSubmit,
    reset,
    getValues,
    formState: { errors },
  } = useForm<EditProfileInformation>({
    mode: 'onBlur',
    resolver: yupResolver(EditProfileInformationSchema),
  })

  useEffect(() => {
    if (user) {
      reset(user)
    }
  }, [reset, user])

  const [updateUser, isUpdatingUser, isUpdatingUserError, resetUpdateUser] = useUpdateUserProfile()

  const onSubmit = async (data: EditProfileInformation) => {
    if (user && data) {
      await updateUser({ ...user, ...data }).then((cUser: User) => {
        resetUpdateUser()
        if (cUser) handleClose()
      })
    }
  }

  const handlePasswordReset = async () => {
    await instance.loginRedirect({
      authority: `${process.env.REACT_APP_B2C_AUTHORITY}B2C_1_ServiceProvidersResetPassword`,
    } as RedirectRequest)
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth="md"
      keepMounted
      transitionDuration={0}
      disablePortal
    >
      <DialogTitle style={{ borderBottom: '1px solid' }} id="alert-dialog-title" sx={{ textAlign: 'center' }}>
        {t('profile.title')}
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 15,
            top: 15,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <PageSpinner isLoading={isLoadingUser} />
        <Grid container spacing={2} sx={{ mt: 1 }} justifyContent="center">
          <Grid item sm={12}>
            <TextField
              select
              id="language"
              label={t('common.language')}
              {...register('language')}
              error={!!errors.language}
              defaultValue="en"
              InputLabelProps={{ shrink: true }}
              fullWidth
            >
              <option value="en">{t('common.english')}</option>
              <option value="fr">{t('common.french')}</option>
            </TextField>
          </Grid>
          <Grid item xs={12} md={6}>
            <InputTextField
              label={t('common.firstName')}
              fullWidth
              {...register('firstName')}
              error={errors.firstName}
              inputProps={{ maxLength: 50 }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <InputTextField
              label={t('common.lastName')}
              fullWidth
              {...register('lastName')}
              error={errors.lastName}
              inputProps={{ maxLength: 50 }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <InputTextField
              label={t('common.jobTitle')}
              fullWidth
              {...register('jobTitle')}
              error={errors.jobTitle}
              inputProps={{ maxLength: 100 }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <InputTextFieldWithMask
              defaultValue={getValues(`phone`)}
              label={t('common.phone')}
              fullWidth
              {...register('phone')}
              error={errors.phone}
              id="cellPhone"
              mask={EMask.phoneNumberMask}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Grid container spacing={1} justifyContent="right" textAlign="center" marginRight={2}>
          <Grid item xs={12} md={4} hidden>
            <AsyncActionButton fullWidth variant="outlined" disabled>
              {t('profile.changeSecurityQuestion')}
            </AsyncActionButton>
          </Grid>
          <Grid item xs={12} md={4}>
            <AsyncActionButton fullWidth variant="outlined" onClick={handlePasswordReset} disabled={isUpdatingUser}>
              {t('profile.changePassword')}
            </AsyncActionButton>
          </Grid>
          <Grid item xs={12} md={4}>
            <AsyncActionButton
              fullWidth
              variant="outlined"
              isPending={isUpdatingUser}
              isError={isUpdatingUserError}
              onClick={handleSubmit(onSubmit)}
            >
              {t('profile.updateInformation')}
            </AsyncActionButton>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  )
}

export default EditProfileDialog
