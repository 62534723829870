import { Typography } from '@mui/material'
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline'
import HighlightOffIcon from '@mui/icons-material/HighlightOff'
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'
import {
  Info as InfoIcon,
  AccessTimeOutlined as AccessTimeOutlinedIcon,
  HelpOutline as HelpOutlineIcon,
} from '@mui/icons-material/'
import { EStatusBackgroundColor, EStatusTextColor, IconType } from '@src/types/Constants'

type Props = {
  iconMessage?: string
  iconType?: IconType | null
  onClick?: () => void
}
const IconSection = ({ iconMessage, iconType, onClick }: Props) => {
  let backgroundColor: EStatusBackgroundColor | null
  let icon: JSX.Element | null
  switch (iconType) {
    case IconType.success:
      icon = <CheckCircleOutlineIcon style={{ color: EStatusTextColor.success, marginRight: '2px' }} />
      backgroundColor = EStatusBackgroundColor.success
      break
    case IconType.error:
      icon = <HighlightOffIcon style={{ color: EStatusTextColor.error, marginRight: '2px' }} />
      backgroundColor = EStatusBackgroundColor.error
      break
    case IconType.warning:
      icon = <ErrorOutlineIcon style={{ color: EStatusTextColor.warning, marginRight: '2px' }} />
      backgroundColor = EStatusBackgroundColor.warning
      break
    case IconType.info:
      icon = <InfoIcon style={{ color: EStatusTextColor.info, marginRight: '2px' }} />
      backgroundColor = EStatusBackgroundColor.info
      break
    case IconType.waiting:
      icon = <AccessTimeOutlinedIcon style={{ color: EStatusTextColor.info, marginRight: '2px' }} />
      backgroundColor = EStatusBackgroundColor.info
      break
    default:
      icon = null
      backgroundColor = null
  }

  return (
    <button
      onClick={onClick}
      style={{
        cursor: onClick ? 'pointer' : 'default',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: backgroundColor || 'white',
        margin: '-8px',
        paddingTop: '20px',
        paddingBottom: '10px',
        borderRadius: '1px 1px 8px 8px',
        border: '1px',
        boxShadow: onClick && '0px 3px 5px rgba(0, 0, 0, 0.2)',
        position: 'relative',
      }}
    >
      <Typography variant="body1" fontWeight="bold" mr={1}>
        {iconMessage}
      </Typography>
      {onClick ? <HelpOutlineIcon style={{ color: EStatusTextColor.warning }} /> : icon}
    </button>
  )
}

export default IconSection
