import React, { useEffect, useState } from 'react'
import AsyncActionButton from '@src/components/AsyncActionButton'
import { useSendSignatureLink } from '@src/data/api/worksheet-api/worksheet-api'
import { useCreditApplicationById } from '@src/data/api/credit-api/credit-api'
import { ButtonProps } from '@mui/material'
import { CreditApplication } from '@src/types/CreditApplicationSchema'
import { useTranslation } from 'react-i18next'
import { EContractStatus } from '@src/types/Constants'

interface Props extends ButtonProps {
  creditApp: CreditApplication
}

const SignDigitallyButton = ({ creditApp }: Props) => {
  const { t } = useTranslation()
  const [shouldPoll, setShouldPoll] = useState(creditApp.contract.status === EContractStatus.sending)
  const [sendSignatureLink, isSendingSignatureLink, isSendingSignatureLinkError, isSendingSignatureLinkSuccess] =
    useSendSignatureLink(creditApp.financingProgramId, creditApp.id)

  const [fullCreditApplication, isFetching] = useCreditApplicationById(creditApp.id, shouldPoll)
  const contract = fullCreditApplication?.contract

  useEffect(() => {
    setShouldPoll(contract.status === EContractStatus.sending)
  }, [contract])

  const handleClick = async () => {
    await sendSignatureLink(creditApp.id)
  }

  return (
    <AsyncActionButton
      fullWidth
      variant="contained"
      isPending={isSendingSignatureLink || shouldPoll}
      isError={isSendingSignatureLinkError}
      isSuccess={isSendingSignatureLinkSuccess}
      onClick={handleClick}
      disabled={isFetching || shouldPoll}
    >
      {t('worksheet.signDigitally')}
    </AsyncActionButton>
  )
}

export default SignDigitallyButton
