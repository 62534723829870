import { Box } from '@mui/material'
import AsyncActionButton from '@src/components/AsyncActionButton'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

const FundingSummaryButtonGroup = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  return (
    <Box
      sx={{
        marginTop: 5,
        marginBottom: 5,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        '@media print': {
          display: 'none',
        },
      }}
    >
      <AsyncActionButton
        sx={{
          width: '20%',
          display: 'block',
          marginRight: 2,
        }}
        variant="contained"
        color="error"
        onClick={() => window.print()}
      >
        {t('fundingSummary.print')}
      </AsyncActionButton>
      <AsyncActionButton
        fullWidth={false}
        sx={{
          width: '20%',
          display: 'block',
        }}
        variant="contained"
        onClick={() => navigate(-1)}
      >
        {t('common.previous')}
      </AsyncActionButton>
    </Box>
  )
}

export default FundingSummaryButtonGroup
