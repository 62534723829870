import React from 'react'
import { Control, Controller, FieldError, FieldErrorsImpl, Merge, UseFormRegister } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Grid } from '@mui/material'
import { Address } from '@src/types/AddressSchema'
import InputTextField from '@src/components/InputTextField'
import { SelectValueListItem } from '@src/types/SelectValueListItem'
import SelectComponent from '@src/components/SelectComponent'
import InputTextFieldWithMask from '@src/components/InputMask'
import { EMask, EProvince, EProvinceList } from '@src/types/Constants'

type Props = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  register: UseFormRegister<any>
  errors?: Merge<FieldError, FieldErrorsImpl<Address>>
  name: string
  isCommercial?: boolean
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  formControl: Control<any>
  includeDuration?: boolean
}

const AddressComponent = ({ register, errors, name, isCommercial, formControl, includeDuration }: Props) => {
  const { t } = useTranslation()
  return (
    <Grid container item xs={12} spacing={2} alignContent="space-between">
      <input type="hidden" {...register(`${name}.country`)} value="Canada" />
      <Grid item xs={12} md={3}>
        <InputTextField
          id="civicNumver"
          error={errors?.civicNumber}
          label={t('editCreditApplication.address.addressNumber')}
          {...register(`${name}.civicNumber`)}
          inputProps={{ maxLength: 10 }}
        />
      </Grid>
      <Grid item xs={12} md={3}>
        <InputTextField
          id="street"
          error={errors?.street}
          label={t('editCreditApplication.address.street')}
          {...register(`${name}.street`)}
          inputProps={{ maxLength: 100 }}
        />
      </Grid>
      {!isCommercial && (
        <Grid item xs={12} md={3}>
          <InputTextField
            id="apartment"
            error={errors?.apartment}
            label={t('editCreditApplication.address.apartment')}
            {...register(`${name}.apartment`)}
            inputProps={{ maxLength: 50 }}
          />
        </Grid>
      )}
      {isCommercial && (
        <Grid item xs={12} md={3}>
          <InputTextField
            id="suite"
            error={errors?.suite}
            label={t('editCreditApplication.address.suite')}
            {...register(`${name}.suite`)}
            inputProps={{ maxLength: 10 }}
          />
        </Grid>
      )}
      <Grid item xs={12} md={3}>
        <InputTextField
          id="city"
          error={errors?.city}
          label={t('editCreditApplication.address.city')}
          {...register(`${name}.city`)}
          inputProps={{ maxLength: 50 }}
        />
      </Grid>
      <Grid item xs={12} md={3}>
        <Controller
          name={`${name}.postalCode`}
          control={formControl}
          render={({ field }) => (
            <InputTextFieldWithMask
              id="postalCode"
              mask={EMask.postalCode}
              error={errors?.postalCode}
              label={t('editCreditApplication.address.postalCode')}
              {...field}
            />
          )}
        />
      </Grid>
      <Grid item xs={12} md={3}>
        <SelectComponent
          valueList={EProvinceList.filter(
            (value) =>
              value !== EProvince.northwestTerritories &&
              value !== EProvince.nunavut &&
              value !== EProvince.yukon &&
              value !== EProvince.princeEdwardIsland,
          )
            .sort()
            .map((item) => {
              const data: SelectValueListItem = { label: `province.${item}`, value: item }
              return data
            })}
          label={t('editCreditApplication.address.province') as string}
          {...register(`${name}.stateIso`)}
          error={errors?.stateIso}
        />
      </Grid>
      {includeDuration && (
        <>
          <Grid item xs={12} md={3}>
            <InputTextField
              id="addressSinceYears"
              type="number"
              error={errors?.years}
              label={t('editCreditApplication.address.since.years')}
              {...register(`${name}.years`)}
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <InputTextField
              id="addressSinceMonths"
              type="number"
              error={errors?.months}
              label={t('editCreditApplication.address.since.months')}
              {...register(`${name}.months`)}
            />
          </Grid>
        </>
      )}
    </Grid>
  )
}

AddressComponent.defaultProps = {
  includeDuration: true,
  isCommercial: false,
  errors: undefined,
}

export default AddressComponent
