import AsyncActionButton from '@src/components/AsyncActionButton'
import { CreditApplication } from '@src/types/CreditApplicationSchema'
import { useNavigate } from 'react-router-dom'
import { EWorksheetStatus } from '@src/types/Constants'
import { useTranslation } from 'react-i18next'

type Props = {
  creditApp: CreditApplication
}

const CreateContractButton = ({ creditApp }: Props) => {
  const { t } = useTranslation()
  const isDraft = creditApp.worksheet?.status === EWorksheetStatus.Draft
  const buttonText =
    creditApp.worksheet != null && !isDraft ? t('worksheet.editWorksheet') : t('worksheet.createContract')
  const navigate = useNavigate()

  return (
    <AsyncActionButton fullWidth variant="contained" onClick={() => navigate(`/credit/${creditApp.id}/Worksheet`)}>
      {buttonText}
    </AsyncActionButton>
  )
}

export default CreateContractButton
