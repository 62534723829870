import { CircleNotifications as CircleNotificationsIcon } from '@mui/icons-material/'
import Tooltip from '@mui/material/Tooltip'
import UploadFileRoundedIcon from '@mui/icons-material/UploadFileRounded'
import { RequiredDocument } from '@src/types/RequiredDocument'
import { List, ListItem } from '@mui/material'
import { useCallback } from 'react'
import { EApplicantType, EOriginSystemId, MerchantNotificationCode } from '@src/types/Constants'

import { useTranslation } from 'react-i18next'
import VisibilityIcon from '@mui/icons-material/Visibility'

type Props = {
  merchantNotificationCodes: MerchantNotificationCode[]
  requiredDocuments: RequiredDocument[]
  originSystemId: EOriginSystemId
}

const FR = 'fr'

const MerchantNotificationIcons = ({ merchantNotificationCodes, requiredDocuments, originSystemId }: Props) => {
  const { t } = useTranslation()
  const { i18n } = useTranslation()
  const getFullDocumentName = useCallback(
    (documentRequired: RequiredDocument) => {
      let applicantSuffix = ''
      if (documentRequired.applicantType === EApplicantType.Applicant) {
        applicantSuffix = ` - ${t('common.applicant')}`
      } else if (documentRequired.applicantType === EApplicantType.Coapplicant) {
        applicantSuffix = ` - ${t('common.coApplicant')}`
      }

      return i18n.resolvedLanguage === FR
        ? `${documentRequired.nameFr}${applicantSuffix}`
        : `${documentRequired.nameEn}${applicantSuffix}`
    },
    [i18n.resolvedLanguage, t],
  )

  return (
    <div>
      {merchantNotificationCodes.length > 0 && (
        <Tooltip
          title={merchantNotificationCodes.map((notification, index) => (
            <div key={notification}>
              {t(`notificationsTitles.${notification}`)}
              {index !== merchantNotificationCodes.length - 1 && (
                <>
                  <br />
                  <br />
                </>
              )}
            </div>
          ))}
        >
          <CircleNotificationsIcon />
        </Tooltip>
      )}
      {requiredDocuments.length > 0 && originSystemId === EOriginSystemId.MerchantDashboard && (
        <Tooltip
          title={
            <List>
              {t(`common.requiredDocuments`)}
              {requiredDocuments.map((doc) => {
                return <ListItem key={doc.typeId}>{getFullDocumentName(doc)}</ListItem>
              })}
            </List>
          }
        >
          <UploadFileRoundedIcon />
        </Tooltip>
      )}
      {originSystemId !== EOriginSystemId.MerchantDashboard && (
        <Tooltip
          title={
            originSystemId === EOriginSystemId.Backoffice
              ? t('viewCreditApplication.readOnlyBackOffice')
              : t('viewCreditApplication.readOnlySubscription')
          }
        >
          <VisibilityIcon />
        </Tooltip>
      )}
    </div>
  )
}

export default MerchantNotificationIcons
