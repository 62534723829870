import { CircularProgress, IconButton, Stack } from '@mui/material'
import UploadFileIcon from '@mui/icons-material/UploadFile'
import { useUploadRequiredDocument } from '@src/data/api/credit-api/credit-api'
import { Dispatch, useCallback } from 'react'
import { Constants, EApplicantType, EDocumentStatus } from '@src/types/Constants'
import AsyncActionButton from '@src/components/AsyncActionButton'
import { useTranslation } from 'react-i18next'

type Props = {
  applicantType: EApplicantType
  typeId: number
  subKey: string
  creditApplicationId: string
  status: EDocumentStatus
  setError: Dispatch<Error>
}

const isSubmited = (cStatus: EDocumentStatus) =>
  cStatus === EDocumentStatus.Approved || cStatus === EDocumentStatus.AwaitingApproval

const UploadFileButton = ({ applicantType, creditApplicationId, typeId, subKey, status, setError }: Props) => {
  const { t } = useTranslation()
  const MAX_FILE_SIZE = 10485760 // 10MB
  const [uploadDocument, isPending, isError] = useUploadRequiredDocument()

  const onChangeHandler = useCallback(
    async (event: React.ChangeEvent<HTMLInputElement>) => {
      const { files } = event.target
      if (files) {
        const filesArray = Array.from(files)
        let hasError = false
        filesArray.forEach((file) => {
          if (file.size > MAX_FILE_SIZE) {
            setError(new Error(t(`common.errors.fileSizeTooBig`)))
            hasError = true
          }

          const fileExtension = file.name.split('.').pop()?.toLowerCase() ?? ''
          const isExtensionSupported = Constants.SupportedDocumentTypes.includes(fileExtension)

          if (!isExtensionSupported) {
            setError(
              new Error(t(`common.errors.unsupportedFileType`, { supportedTypes: Constants.SupportedDocumentTypes })),
            )
            hasError = true
          }
        })
        if (!hasError) {
          await uploadDocument({
            creditApplicationId,
            applicantType,
            typeId,
            subKey,
            files: filesArray,
          })
        } else window.scrollTo({ top: 0, behavior: 'smooth' })
      }
    },
    [applicantType, creditApplicationId, setError, subKey, t, typeId, uploadDocument],
  )

  const getUploadButton = () => {
    const uploadSpinner = (
      <IconButton>
        <CircularProgress />
      </IconButton>
    )
    const uploadArrowIcon = (
      <AsyncActionButton
        fullWidth={false}
        variant="contained"
        color="primary"
        component="label"
        isError={isError}
        sx={{ marginLeft: 0, minWidth: 'auto' }}
      >
        <input
          type="file"
          hidden
          accept={Constants.SupportedDocumentTypes}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => onChangeHandler(event)}
        />
        <UploadFileIcon fontSize="small" />
      </AsyncActionButton>
    )

    if (isPending) {
      return uploadSpinner
    }

    if (status === EDocumentStatus.Incomplete || !isSubmited(status)) return uploadArrowIcon
    return null
  }

  return (
    <Stack direction="row" marginTop="-0.5rem" justifyContent="center">
      {getUploadButton()}
    </Stack>
  )
}

export default UploadFileButton
