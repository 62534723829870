import { Chip, TableCell, TableRow } from '@mui/material'
import { formatDate } from '@src/services/Formatter'
import { RequiredDocument } from '@src/types/RequiredDocument'
import { useTranslation } from 'react-i18next'
import { Dispatch } from 'react'
import DocumentNote from './DocumentNote'
import UploadFileButton from './UploadFileButton'
import { useDocumentStatus, useFullNameText } from './documentTableRow-hooks'

type Props = {
  documentRequired: RequiredDocument
  creditApplicationId: string
  setError: Dispatch<Error>
  canEdit: boolean
}

const DocumentTableRow = ({ documentRequired, creditApplicationId, setError, canEdit }: Props) => {
  const { t } = useTranslation()
  const { statusMessage, textColor, backgroundColor } = useDocumentStatus(documentRequired)
  const fullNameText = useFullNameText(documentRequired)

  return (
    <TableRow>
      <TableCell style={{ borderBottom: 'none', fontWeight: 'bold' }}>{fullNameText}</TableCell>
      <TableCell style={{ borderBottom: 'none', textAlign: 'center' }}>
        {documentRequired.requiredBeforeStep && t(`requiredBeforeStep.${documentRequired.requiredBeforeStep}`)}
      </TableCell>
      <TableCell style={{ borderBottom: 'none', textAlign: 'center' }}>
        {formatDate(documentRequired.createdOn)}
      </TableCell>
      <TableCell style={{ borderBottom: 'none', textAlign: 'center' }}>
        {documentRequired.receivedOn && formatDate(documentRequired.receivedOn)}
      </TableCell>
      <TableCell
        style={{
          borderBottom: 'none',
          textAlign: 'center',
        }}
      >
        <Chip
          style={{
            color: textColor,
            backgroundColor,
            fontWeight: 'bold',
          }}
          label={statusMessage}
        />
      </TableCell>
      <TableCell sx={{ maxWidth: '18rem', borderBottom: 'none' }}>
        <DocumentNote message={documentRequired.note} />
        <DocumentNote message={documentRequired.refusalReason} color={textColor} />
      </TableCell>
      {canEdit && (
        <TableCell sx={{ borderBottom: 'none', textAlign: 'center' }}>
          <UploadFileButton
            applicantType={documentRequired.applicantType}
            typeId={documentRequired.typeId}
            subKey={documentRequired.subKey}
            status={documentRequired.status}
            creditApplicationId={creditApplicationId}
            setError={setError}
          />
        </TableCell>
      )}
    </TableRow>
  )
}

export default DocumentTableRow
