// doit être fait en deux lignes sinon webpack optimise et n'inclut pas la string
// de plus, le toString est requis car en local c'est un string alors qu'en dev c'est une string
const useDevToolsStr = process.env.REACT_APP_USE_DEV_TOOLS ?? 'false'
export const useDevTools = useDevToolsStr.toString() === 'true'

export enum EServiceCategory {
  Veterinary = 'veterinary',
  Medical = 'medical',
  Aesthetics = 'aesthetics',
  Dentistry = 'dentistry',
  GoodsAndServices = 'goodsAndServices',
}

export enum EMerchantType {
  Merchant = 0,
  Banner = 1,
}

export enum EThemeName {
  Dark = 'dark',
  Light = 'light',
}

export enum EFinancingProgram {
  Auto = 'auto',
  IfXpress = 'ifxpress',
  Vlvr = 'vlvr',
  Products = 'products',
  Personal = 'personal',
}

export enum EHomeStatus {
  Owner = 'own',
  Renter = 'rent',
  OwnMobileHome = 'ownMobileHome',
  WithParents = 'withParents',
  ReserveHousing = 'reserveHousing',
  OwnWithMortgage = 'ownWithMortgage',
  Other = 'other',
}

export enum EOtherIncomeType {
  familyAllocations = 1,
  workIncidentIndemnisation = 3,
  maternityPaternityIncome = 4,
  other = 6,
  retirementIncome = 8,
  disabilityIncome = 11,
  incomeServiceAdjustment = 12,
  assistance = 13,
  employmentInsurance = 14,
}
export const EOtherIncomeTypeList = Object.values(EOtherIncomeType).filter(
  (x) => typeof x === 'number',
) as EOtherIncomeType[]

export enum ECreditApplicationStatus {
  Draft = 'draft',
  Active = 'active',
  Completed = 'completed',
  Cancelled = 'cancelled',
  Expired = 'expired',
  Declined = 'declined',
  ManualRevision = 'manualRevision',
  DocumentsRequired = 'documentsRequired',
  Error = 'applicationError',
  Progress = 'inProgress',
  Waiting = 'waiting',
  Processing = 'processing',
  ActionRequired = 'actionRequired',
  AwaitingSignature = 'awaitingSignature',
  AwaitingApproval = 'awaitingApproval',
}

export enum EBeneficiaryType {
  NotSet = 0,
  Applicant = 1,
  Coapplicant = 2,
  Other = 3,
}

export enum EOriginSystemId {
  Backoffice = 'backoffice',
  Subscription = 'ifinancesub',
  MerchantDashboard = 'merchdb',
}

export enum EDecision {
  Pending = 'pending',
  Accept = 'approved',
  ManualRevision = 'manual-revision',
  Refused = 'refused',
  Error = 'error',
  None = '',
}

export enum EProvince {
  quebec = 'QC',
  ontario = 'ON',
  novaScotia = 'NS',
  newBrunswick = 'NB',
  princeEdwardIsland = 'PE',
  newfoundland = 'NL',
  britishColombia = 'BC',
  saskatchewan = 'SK',
  alberta = 'AB',
  manitoba = 'MB',
  yukon = 'YT',
  nunavut = 'NU',
  northwestTerritories = 'NT',
}
export const EProvinceList = Object.values(EProvince)

export enum EMask {
  phoneNumberMask = '000-000-0000',
  sinMask = '000 000 000',
  postalCode = 'a0a 0a0',
}

export enum EGender {
  men = 0,
  woman = 1,
  other = 2,
}

export const EGenderList = Object.values(EGender).filter((x) => typeof x === 'number')

export enum EMaritalStatus {
  single = 0,
  married = 1,
  deFactoCouple = 2,
  widowWidower = 3,
  undefined = 999,
}
export const EMaritalStatusList = Object.values(EMaritalStatus).filter((x) => typeof x === 'number')

export enum ERelationToApplicant {
  Spouse = 'SP',
  CommonLaw = 'CL',
  Employer = 'Employer',
  InLaw = 'IL',
  Parental = 'PR',
  GrandMother = 'GM',
  GrandFather = 'GF',
  Brother = 'B',
  Sister = 'S',
  Other = 'Other',
}
export const ERelationToApplicantList = Object.values(ERelationToApplicant)

export enum EJobType {
  fullTime = 0,
  partTime = 1,
  Seasonal = 3,
  SelfEmployed = 5,
}
export const EJobTypeList = Object.values(EJobType).filter((x) => typeof x === 'number') as EJobType[]

export enum EDocumentStatus {
  AwaitingDocument = 'awaitingDocument',
  AwaitingScan = 'awaitingScan',
  AwaitingApproval = 'awaitingApproval',
  Incomplete = 'incomplete',
  Deleted = 'deleted',
  Refused = 'refused',
  Approved = 'approved',
}

export enum ELanguage {
  French = 0,
  English = 1,
}

export const ELanguageList = Object.values(ELanguage).filter((x) => typeof x === 'number')

export enum EIconType {
  success = 'success',
  warning = 'warning',
  error = 'error',
}

export enum EStatusTextColor {
  success = 'green',
  warning = '#cf7b2f',
  error = 'red',
  info = '#0061c2',
}

export enum EStatusBackgroundColor {
  success = '#90ee90',
  warning = '#fdf2e2',
  error = '#ffb6c1',
  info = '#a2caf3',
}

export enum EApplicantType {
  Applicant = 'applicant',
  Coapplicant = 'coApplicant',
}

export enum ECreditApplicationStep {
  Financing = 'financing',
  Credit = 'credit',
  PayOut = 'payout',
  CVT = 'cvt',
}

export enum ECurrentCustomerStep {
  Draft = 'draft',
  Prequal = 'prequal',
  Credit = 'credit',
  IncomeVerification = 'incomeVerification',
  Contract = 'contract',
  Funding = 'funding',
}

export enum IconType {
  success = 'success',
  warning = 'warning',
  error = 'error',
  info = 'info',
  waiting = 'waiting',
}

export const Constants = {
  SystemUserId: 'system',
  MinimumLoanAmount: 500,
  MaximumLoanAmount: 40000,
  SupportedDocumentTypes: '.jpg, .jpeg, .png, .pdf, .heic, .heif',
  MaxYearsToDisplay: 125,
}

export const MinimumAgeRequirementByStateIso = {
  quebec: 18,
  ontario: 18,
  novaScotia: 19,
  newBrunswick: 19,
  princeEdwardIsland: 18,
  newfoundland: 19,
  britishColombia: 18,
  saskatchewan: 18,
  alberta: 18,
  manitoba: 18,
  yukon: 18,
  nunavut: 18,
  northwestTerritories: 18,
}

export enum EContractStatus {
  ready = 'ready',
  sending = 'sending',
  declined = 'declined',
  notReady = 'notready',
  underReview = 'review',
  completed = 'completed',
}
export enum EWorksheetStatus {
  Draft = 'draft',
  Active = 'active',
  Completed = 'completed',
}

export enum EPaymentFrequency {
  Monthly = 'monthly',
  BiWeekly = 'biWeekly',
}

export enum PaymentMethod {
  check = 'check',
  bankTransfer = 'bankTransfer',
  DEFT = 'DEFT',
  WIRE = 'Wire',
}
export enum EPaymentPlan {
  regularDailyInterests = 'regularInterest',
}
export enum MerchantId {
  IfinanceMerchantId = 'alis*20500',
  MedicardMerchantId = 'alis*20878',
}

export enum EDocType {
  signedCvt = 47,
  LastPayStubJob = 17,
  SixMonthsPersonalBankStatements = 36,
  VoidCheque = 44,
  FamilyAllocationsProof = 68,
  WorkIncidentIndemnisationProof = 69,
  MaternityPaternityIncomeProof = 70,
  OtherIncomeProof = 71,
  RetirementIncomeProof = 72,
  DisabilityIncomeProof = 73,
  AssistanceProof = 74,
  EmployementInsuranceProof = 75,
  ValidPhotoId = 67,
}

export enum EsupportedPaymentFrequency {
  monthly = 'Monthly',
  biweekly = 'biWeekly',
}

export enum MerchantNotificationCode {
  HardHitMissing = 'HardHitMissing',
  IncomeVerificationMissing = 'IncomeVerificationMissing',
  ContractMissing = 'ContractMissing',
}

export const HiddenMerchantCategories = [
  EServiceCategory.Dentistry,
  EServiceCategory.Medical,
  EServiceCategory.Aesthetics,
]
