import { QueryFunctionContext, useQuery } from '@tanstack/react-query'
import { getApiClient } from './api-client'

const SCOPE = 'global'
const VERSION = 'version'

const keysFactory = {
  version: () => [{ scope: SCOPE, entity: VERSION }] as const,
}

const performVersionCheck = async ({
  // eslint-disable-next-line no-empty-pattern
  queryKey: [],
}: QueryFunctionContext<ReturnType<(typeof keysFactory)['version']>>) => {
  const apiClient = getApiClient()
  const response = await apiClient.get('/VersionCheck')
  return response.headers['x-requestor-latest-version'] as string
}

export default function useVersionCheck(): [string, boolean] {
  const { isFetching, data } = useQuery({
    queryKey: keysFactory.version(),
    queryFn: performVersionCheck,
    refetchOnWindowFocus: 'always',
  })
  return [data!, isFetching]
}
