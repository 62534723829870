import { green, grey, orange, red } from '@mui/material/colors'
import { ECreditApplicationStatus, EDecision, EDocType } from '@src/types/Constants'
import { RequiredDocument } from '@src/types/RequiredDocument'

export const getPrequalColor = (creditApplicationStatus: ECreditApplicationStatus, prequalDecision: EDecision) => {
  if (creditApplicationStatus === ECreditApplicationStatus.Draft) {
    return grey[500]
  }
  if (prequalDecision === EDecision.Refused) {
    return red[500]
  }
  if (prequalDecision === EDecision.Accept) {
    return green[500]
  }
  return orange[500]
}

export const getCreditColor = (prequalDecision: EDecision, hardHitDecision: EDecision) => {
  if (hardHitDecision === EDecision.Refused) {
    return red[500]
  }
  if (hardHitDecision === EDecision.Accept) {
    return green[500]
  }
  if (prequalDecision === EDecision.Accept && hardHitDecision === EDecision.None) {
    return orange[500]
  }

  return grey[500]
}

export const getIncomeVerificationColor = (
  prequalDecision: EDecision,
  hardHitDecision: EDecision,
  isIncomeConfirmed: boolean,
) => {
  if (isIncomeConfirmed) {
    return green[500]
  }
  if (prequalDecision === EDecision.Accept && hardHitDecision === EDecision.Accept) {
    return orange[500]
  }

  return grey[500]
}

export const getContractColor = (
  hardHitDecision: EDecision,
  isIncomeConfirmed: boolean,
  isContractSignatureMethodSelected: boolean,
  missingRequiredDocuments: RequiredDocument[],
) => {
  if (hardHitDecision === EDecision.Accept && isIncomeConfirmed === true) {
    const hasCvtSigned = !missingRequiredDocuments.some((doc) => doc.typeId === EDocType.signedCvt)
    if (isContractSignatureMethodSelected === true && hasCvtSigned === true) {
      return green[500]
    }
    return orange[500]
  }

  return grey[500]
}

export const getFundingColor = (
  status: string,
  hardHitDecision: EDecision,
  isIncomeConfirmed: boolean,
  isContractSignatureMethodSelected: boolean,
) => {
  if (status === 'PP') {
    return green[500]
  }
  if (
    isContractSignatureMethodSelected === true &&
    hardHitDecision === EDecision.Accept &&
    isIncomeConfirmed === true
  ) {
    return orange[500]
  }

  return grey[500]
}
